import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Logo, ProfileImage } from "../../constant/Index";
import "../../assets/css/variable.css";
import "../../assets/css/style.css";
import ScrollTop from "../ScrollTop/ScrollTop";
import AllModals from "../AllModals/AllModals";
import { connect, useDispatch } from "react-redux";
import MenuItem from "../MenuItem";
import { LOGOUT } from "../../redux/actions/types";
import config from "../../config";

const Header = ({ categories, auth, cart }) => {
  const location = useLocation();
  const currentLocation = location.pathname;

  const [mobile, setMobile] = useState(false);

  return (
    <>
      <ScrollTop />

      {/* Header Start Here */}
      <header className="top-header">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-lg-2 col-5">
              <div className="logo-box">
                <Link to="/">
                  <figure>
                    <img src={Logo} alt="header-logo" className="img-fluid" />
                  </figure>
                </Link>
              </div>
            </div>
            <div className="col-lg-10 col-7">
              <ul className="menus">
                <li className="menu-items">
                  <Link
                    to="https://identity.document360.io/Account/Login?ReturnUrl=%2Fconnect%2Fauthorize%2Fcallback%3Fclient_id%3Ddocument360MVC%26redirect_uri%3Dhttps%253A%252F%252Fbug-control.document360.io%252Fsignin-oidc%26response_type%3Dcode%26scope%3Dopenid%2520email%2520profile%26code_challenge%3DHXRK5HPW_pXmhxEzcB63eeM-btcUJ-qNtV_w2ViH2ew%26code_challenge_method%3DS256%26nonce%3D638120148667997455."
                    target="_blank"
                    className="menu-links"
                  >
                    Knowledge Base Login
                  </Link>
                </li>
                <li className="menu-items">
                  <Link
                    to="https://infectioncontrol.talentlms.com/index"
                    target="_blank"
                    className="menu-links"
                  >
                    LMS Login
                  </Link>
                </li>
                <li className="menu-items">
                  <Link
                    to="/packages"
                    className={
                      currentLocation == "/packages"
                        ? "menu-links active"
                        : "menu-links"
                    }
                  >
                    Subscription
                  </Link>
                </li>
                <li className="menu-items">
                  <Link
                    to="/lead-course"
                    className={
                      currentLocation == "/lead-course"
                        ? "menu-links active"
                        : "menu-links"
                    }
                  >
                    Education
                  </Link>
                </li>
                <li className="menu-items dropdown">
                  <Link
                    to="/shop"
                    className={
                      currentLocation == "/shop"
                        ? "menu-links active"
                        : "menu-links dropdown"
                    }
                  >
                    Shop <i className="fa fa-angle-down" aria-hidden="true"></i>
                  </Link>
                  <ul
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuButton1"
                  >
                    <li>
                      <Link
                        className={
                          currentLocation == "/shop"
                            ? "dropdown-item active"
                            : "dropdown-item"
                        }
                        to="/shop"
                      >
                        View All
                      </Link>
                    </li>
                    {categories.map((singleCategory) => (
                      <MenuItem
                        slug={singleCategory.slug}
                        hasChild={singleCategory.child_categories.length > 0}
                        title={singleCategory.name}
                        submenus={singleCategory.child_categories}
                        key={`menu_${singleCategory.id}`}
                      />
                    ))}
                    {/* <li className="inner-dropdown">
                      <Link
                        className={
                          currentLocation == "/educational-resource"
                            ? "dropdown-item active"
                            : "dropdown-item"
                        }
                        to="/educational-resource"
                      >
                        Educational Resources
                      </Link>
                      <ul className="dropdown-innermenu">
                        <li>
                          <Link
                            className={
                              currentLocation == "/ams-compendiums"
                                ? "menu-links active"
                                : "menu-links"
                            }
                            to="/ams-compendiums"
                          >
                            AMS Compendiums & Shop
                          </Link>
                        </li>
                        <li>
                          <Link
                            className={
                              currentLocation == "/hand-hygiene"
                                ? "menu-links active"
                                : "menu-links"
                            }
                            to="/hand-hygiene"
                          >
                            Hand Hygiene Kits
                          </Link>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <Link
                        className={
                          currentLocation == "/flipcharts"
                            ? "dropdown-item active"
                            : "dropdown-item"
                        }
                        to="/flipcharts"
                      >
                        Flipcharts
                      </Link>
                    </li>
                    <li>
                      <Link
                        className={
                          currentLocation == "/control-guide"
                            ? "dropdown-item active"
                            : "dropdown-item"
                        }
                        to="/control-guide"
                      >
                        Infection Prevention and Control Guides
                      </Link>
                    </li> */}
                  </ul>
                </li>
                <li className="menu-items">
                  <Link
                    to="/about-us"
                    className={
                      currentLocation == "/about-us"
                        ? "menu-links active"
                        : "menu-links"
                    }
                  >
                    About
                  </Link>
                </li>
                <li className="menu-items">
                  <Link
                    to="/bc-systems"
                    className={
                      currentLocation == "/bc-systems"
                        ? "menu-links active"
                        : "menu-links"
                    }
                  >
                    BC Systems
                  </Link>
                </li>
                <li className="menu-items">
                  <Link
                    to="/news"
                    className={
                      currentLocation == "/news"
                        ? "menu-links active"
                        : "menu-links"
                    }
                  >
                    News
                  </Link>
                </li>
                <li className="menu-items">
                  <Link
                    to="/contact-us"
                    className={
                      currentLocation == "/contact-us"
                        ? "menu-links active"
                        : "menu-links"
                    }
                  >
                    Contact
                  </Link>
                </li>
                {auth.token ? (
                  <li className="menu-items profile-link">
                    <Link to="/profile" className="menu-links">
                      <div className="icon">
                        <figure>
                          <img
                            src={config.returnAvatar(auth.user)}
                            alt={auth.user.avatar}
                            className="img-fluid rounded-circle"
                          />
                        </figure>
                      </div>
                    </Link>
                  </li>
                ) : (
                  <li className="menu-items">
                    <Link to="/login" className="menu-links">
                      Login
                    </Link>
                  </li>
                )}

                <li className="menu-items">
                  <a href="tel:0800 849 211" className="menu-links">
                    0800 849 211
                  </a>
                </li>
                <li className="cart-icon">
                  <Link to="/product-cart">
                    <span className="icon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="21.5"
                        height="18.694"
                        viewBox="0 0 21.5 18.694"
                      >
                        <g
                          id="Group_1142"
                          data-name="Group 1142"
                          transform="translate(0 1)"
                        >
                          <path
                            id="Path_434"
                            data-name="Path 434"
                            d="M3035.042,1989.882h3.935l2.336,11h11.372l2.52-8.576h-15.636"
                            transform="translate(-3035.042 -1989.882)"
                            fill="none"
                            stroke="#fff"
                            strokeWidth="2"
                          ></path>
                          <path
                            id="Path_433"
                            data-name="Path 433"
                            d="M3048.981,2017.319a1.287,1.287,0,0,0-1.208.9,1.144,1.144,0,0,0,.777,1.407,5.685,5.685,0,0,0,1.3.155l10.559-.015"
                            transform="translate(-3042.642 -2006.316)"
                            fill="none"
                            stroke="#fff"
                            strokeWidth="2"
                          ></path>
                          <g
                            id="Ellipse_14"
                            data-name="Ellipse 14"
                            transform="translate(5.598 14.084)"
                            fill="none"
                            stroke="#fff"
                            strokeWidth="2"
                          >
                            <circle
                              cx="1.805"
                              cy="1.805"
                              r="1.805"
                              stroke="none"
                            ></circle>
                            <circle
                              cx="1.805"
                              cy="1.805"
                              r="0.805"
                              fill="none"
                            ></circle>
                          </g>
                          <g
                            id="Ellipse_15"
                            data-name="Ellipse 15"
                            transform="translate(14.421 14.084)"
                            fill="none"
                            stroke="#fff"
                            strokeWidth="2"
                          >
                            <circle
                              cx="1.805"
                              cy="1.805"
                              r="1.805"
                              stroke="none"
                            ></circle>
                            <circle
                              cx="1.805"
                              cy="1.805"
                              r="0.805"
                              fill="none"
                            ></circle>
                          </g>
                        </g>
                      </svg>
                    </span>
                    {/* {cart.length > 0 ? ( */}
                    <span className="count ml-2">{cart.length}</span>
                    {/* ) : null} */}
                  </Link>
                </li>
              </ul>
              <ul className="for-mobile">
                <li className="canvas">
                  <button
                    className="canvas-btn"
                    onClick={() => setMobile(true)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="30"
                      height="30"
                      fill="currentColor"
                      className="bi bi-list"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fillRule="evenodd"
                        d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"
                      />
                    </svg>
                  </button>
                </li>
                <li className="cart-icon">
                  <Link to="/product-cart">
                    <span className="icon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="21.5"
                        height="18.694"
                        viewBox="0 0 21.5 18.694"
                      >
                        <g
                          id="Group_1142"
                          data-name="Group 1142"
                          transform="translate(0 1)"
                        >
                          <path
                            id="Path_434"
                            data-name="Path 434"
                            d="M3035.042,1989.882h3.935l2.336,11h11.372l2.52-8.576h-15.636"
                            transform="translate(-3035.042 -1989.882)"
                            fill="none"
                            stroke="#fff"
                            strokeWidth="2"
                          ></path>
                          <path
                            id="Path_433"
                            data-name="Path 433"
                            d="M3048.981,2017.319a1.287,1.287,0,0,0-1.208.9,1.144,1.144,0,0,0,.777,1.407,5.685,5.685,0,0,0,1.3.155l10.559-.015"
                            transform="translate(-3042.642 -2006.316)"
                            fill="none"
                            stroke="#fff"
                            strokeWidth="2"
                          ></path>
                          <g
                            id="Ellipse_14"
                            data-name="Ellipse 14"
                            transform="translate(5.598 14.084)"
                            fill="none"
                            stroke="#fff"
                            strokeWidth="2"
                          >
                            <circle
                              cx="1.805"
                              cy="1.805"
                              r="1.805"
                              stroke="none"
                            ></circle>
                            <circle
                              cx="1.805"
                              cy="1.805"
                              r="0.805"
                              fill="none"
                            ></circle>
                          </g>
                          <g
                            id="Ellipse_15"
                            data-name="Ellipse 15"
                            transform="translate(14.421 14.084)"
                            fill="none"
                            stroke="#fff"
                            strokeWidth="2"
                          >
                            <circle
                              cx="1.805"
                              cy="1.805"
                              r="1.805"
                              stroke="none"
                            ></circle>
                            <circle
                              cx="1.805"
                              cy="1.805"
                              r="0.805"
                              fill="none"
                            ></circle>
                          </g>
                        </g>
                      </svg>
                    </span>
                    <span className="count">3</span>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </header>
      {/* Header End Here */}

      {/* Mobile Header Start Here */}
      <div className={mobile ? "mobile-header show" : "mobile-header"}>
        <div className="inner-box">
          <button className="cancel-btn" onClick={() => setMobile(false)}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-x-lg"
              viewBox="0 0 16 16"
            >
              <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
            </svg>
          </button>
          <ul className="mobile-menus">
            <li className="menu-items">
              <Link
                to="https://identity.document360.io/Account/Login?ReturnUrl=%2Fconnect%2Fauthorize%2Fcallback%3Fclient_id%3Ddocument360MVC%26redirect_uri%3Dhttps%253A%252F%252Fbug-control.document360.io%252Fsignin-oidc%26response_type%3Dcode%26scope%3Dopenid%2520email%2520profile%26code_challenge%3DHXRK5HPW_pXmhxEzcB63eeM-btcUJ-qNtV_w2ViH2ew%26code_challenge_method%3DS256%26nonce%3D638120148667997455."
                className="menu-links"
              >
                Knowledge Base Login
              </Link>
            </li>
            <li className="menu-items">
              <Link
                to="https://infectioncontrol.talentlms.com/index"
                className="menu-links"
              >
                LMS Login
              </Link>
            </li>
            <li className="menu-items">
              <Link to="/packages" className="menu-links">
                Subscription
              </Link>
            </li>
            <li className="menu-items">
              <Link to="/lead-course" className="menu-links">
                Education
              </Link>
            </li>
            <li className="menu-items">
              <div id="accordion">
                <div className="card">
                  <div className="card-header" id="headingTwo">
                    <h5 className="mb-0">
                      <button
                        className="btn btn-link collapsed"
                        data-toggle="collapse"
                        data-target="#collapseTwo"
                        aria-expanded="false"
                        aria-controls="collapseTwo"
                      >
                        <Link to="/shop">Shop</Link>
                        <i className="fa fa-angle-down" aria-hidden="true"></i>
                      </button>
                    </h5>
                  </div>
                  <div
                    id="collapseTwo"
                    className="collapse"
                    aria-labelledby="headingTwo"
                    data-parent="#accordion"
                  >
                    <div className="card-body">
                      <ul className="inner-menus">
                        <li className="menu-items">
                          <Link className="menu-links" to="/shop">
                            View All
                          </Link>
                        </li>
                        <li className="menu-items">
                          <Link
                            className="menu-links"
                            to="/educational-resource"
                          >
                            Educational Resources
                          </Link>
                        </li>
                        <li className="menu-items">
                          <Link className="menu-links" to="/flipcharts">
                            Flipcharts
                          </Link>
                        </li>
                        <li className="menu-items">
                          <Link className="menu-links" to="/control-guide">
                            Infection Prevention and Control Guides
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li className="menu-items">
              <Link to="/about-us" className="menu-links">
                About
              </Link>
            </li>
            <li className="menu-items">
              <Link to="/bc-systems" className="menu-links">
                BC Systems
              </Link>
            </li>
            <li className="menu-items">
              <Link to="/news" className="menu-links">
                News
              </Link>
            </li>
            <li className="menu-items">
              <Link to="/contact-us" className="menu-links">
                Contact
              </Link>
            </li>
            <li className="menu-items">
              <Link to="/login" className="menu-links">
                Login
              </Link>
            </li>
            <li className="menu-items">
              <Link to="/profile" className="menu-links">
                Profile
              </Link>
            </li>
          </ul>
        </div>
      </div>
      {/* Mobile Header End Here */}
    </>
  );
};

const mapStateToProps = (state) => ({
  categories: state.categories,
  auth: state.auth,
  cart: state.cart,
});

export default connect(mapStateToProps)(Header);
