import PublicRoutes from "./routes/PublicRoutes";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import store from "./redux/store";

function App() {
  return (
    <Provider store={store.store}>
      <PersistGate persistor={store.persistor}>
        <PublicRoutes />
      </PersistGate>
    </Provider>
  );
}

export default App;
