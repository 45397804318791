import {
  CardElement,
  Elements,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import "../../assets/css/profile.css";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import MyInputFormGroup from "../../components/MyInputFormGroup";
import MySpinner from "../../components/MySpinner";
import config from "../../config";
import { constants, Product1, visa } from "../../constant/Index";
import FormException from "../../exceptions/FormException";
import {
  AddAddressAction,
  GetAddressesAction,
  UpdateAddressAction,
  DeleteAddressAction,
} from "../../redux/actions/addresses";
import { LogoutAction, UpdateProfileAction } from "../../redux/actions/auth";
import { GetOrdersAction } from "../../redux/actions/orders";
import {
  AddPaymentMethodAction,
  GetPaymentMethodsAction,
  UpdatePaymentMethodAction,
} from "../../redux/actions/paymentMethods";

const genderOptions = [
  { label: "Select Gender", value: "" },
  { label: "Male", value: "Male" },
  { label: "Female", value: "Female" },
];

const addressTypeOptions = [
  { label: "Select Address Type", value: "" },
  {
    label: constants.ADDRESS_TYPE_BILIING,
    value: constants.ADDRESS_TYPE_BILIING,
  },
  {
    label: constants.ADDRESS_TYPE_SHIPPING,
    value: constants.ADDRESS_TYPE_SHIPPING,
  },
];

const ProfileInner = ({}) => {
  const user = useSelector((state) => state.auth.user);
  const addresses = useSelector((state) => state.addresses);
  const paymentMethods = useSelector((state) => state.paymentMethods);
  const orders = useSelector((state) => state.orders);

  const dispatch = useDispatch();

  const navigate = useNavigate();
  const [editinfo, setEditinfo] = useState(false);
  const [editadd, setEditadd] = useState(false);

  const [fname, setFname] = useState(user.first_name || "");
  const [lname, setLname] = useState(user.last_name || "");
  const [phone, setPhone] = useState(user.phone || "");
  const [email, setEmail] = useState(user.email || "");
  const [avatar, setAvatar] = useState({});
  const [gender, setGender] = useState(user.gender || null);

  const [label, setLabel] = useState("");
  const [line1, setLine1] = useState("");
  const [line2, setLine2] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zip, setZip] = useState("");
  const [country, setCountry] = useState("");
  const [type, setType] = useState(constants.ADDRESS_TYPE_BILIING);

  const [orderDetails, setOrderDetails] = useState({});

  const [cardError, setCardError] = useState("");

  const [errors, setErrors] = useState({});

  const [spinner, setSpinner] = useState(false);

  const stripe = useStripe();
  const elements = useElements();

  useEffect(() => {
    dispatch(GetAddressesAction());
    dispatch(GetPaymentMethodsAction());
    dispatch(GetOrdersAction());
  }, []);

  const submitLogout = () => {
    dispatch(LogoutAction());
    navigate("/login");
  };

  const submitUpdateProfile = async () => {
    try {
      setSpinner(true);
      let data = new FormData();
      data.append("first_name", fname);
      data.append("last_name", lname);
      data.append("phone", phone);
      data.append("gender", gender);
      if (avatar && avatar.name) {
        data.append("avatar", avatar);
      }
      dispatch(await UpdateProfileAction(data));
      // await updateProfile(data);
      setSpinner(false);
      setErrors({});
      setEditinfo(false);
    } catch (e) {
      setSpinner(false);
      if (e instanceof FormException) {
        setErrors(e.errors);
      }
    }
  };

  const submitAddAddress = async () => {
    try {
      setSpinner(true);
      const data = {
        line_1: line1,
        line_2: line2,
        label,
        city,
        state,
        zip,
        country,
        type,
        is_default: 0,
      };
      dispatch(await AddAddressAction(data));
      setSpinner(false);
      setErrors({});
      setLine1("");
      setLine2("");
      setCity("");
      setState("");
      setZip("");
      setCountry("");
      setLabel("");
      setType(null);
    } catch (e) {
      setSpinner(false);
      if (e instanceof FormException) {
        setErrors(e.errors);
      }
    }
  };

  const changeDefaultAddress = async (id, isDefault) => {
    try {
      setSpinner(true);
      const data = {
        is_default: isDefault,
      };
      dispatch(await UpdateAddressAction(id, data));
      // await updateAddress(id, data);
      setSpinner(false);
      setErrors({});
      dispatch(await GetAddressesAction());
      // await getAdddresses();
    } catch (e) {
      setSpinner(false);
      if (e instanceof FormException) {
        setErrors(e.errors);
      }
    }
  };

  const submitDeleteAddress = async (id) => {
    try {
      setSpinner(true);
      dispatch(await DeleteAddressAction(id));
      // await deleteAddress(id);
      setSpinner(false);
      dispatch(await GetAddressesAction());
      // await getAdddresses();
    } catch (e) {
      console.log(e);
    }
  };

  const addCard = async () => {
    try {
      setSpinner(true);
      const { error, source } = await stripe.createSource(
        elements.getElement(CardElement),
        {
          type: "card",
        }
      );
      if (error) {
        setCardError(error);
        setSpinner(false);
        return false;
      }
      await dispatch(await AddPaymentMethodAction(source.id));
      setSpinner(false);
    } catch (e) {
      setSpinner(false);
      console.log(e);
    }
  };

  const updateCard = async (id, defaultSource) => {
    try {
      setSpinner(true);
      await dispatch(await UpdatePaymentMethodAction(id, defaultSource));
      await dispatch(await GetPaymentMethodsAction());
      setSpinner(false);
    } catch (e) {
      setSpinner(false);
      console.log(e);
    }
  };

  return (
    <>
      {/* Header Start Here */}
      <Header />
      {/* Header End Here */}

      {/* <!-- Profile Sec Start Here --> */}
      <section className="profile-sec">
        <div className="container">
          <div className="heading">
            <h2>My Profile</h2>
          </div>
          <div className="row">
            <div className="col-lg-4">
              <div className="sidebar">
                <div className="user-info">
                  <div className="img-box">
                    <figure>
                      <img
                        src={config.returnAvatar(user)}
                        className="img-fluid"
                      />
                    </figure>
                  </div>
                  <div className="info">
                    <h3>{user.full_name}</h3>
                    {/* <h5>Customer</h5> */}
                  </div>
                </div>
                <div
                  className="nav flex-column nav-pills me-3"
                  id="v-pills-tab"
                  role="tablist"
                  aria-orientation="vertical"
                >
                  {/* <!-- First Tab Button --> */}
                  <button
                    className="nav-link active"
                    id="v-pills-Account-tab"
                    data-toggle="pill"
                    data-target="#v-pills-Account"
                    type="button"
                    role="tab"
                    aria-controls="v-pills-Account"
                    aria-selected="true"
                  >
                    My Account{" "}
                  </button>
                  {/* <!-- First Tab Button --> */}

                  {/* <!-- Second Tab Button --> */}
                  <button
                    className="nav-link"
                    id="v-pills-Addresses-tab"
                    data-toggle="pill"
                    data-target="#v-pills-Addresses"
                    type="button"
                    role="tab"
                    aria-controls="v-pills-Addresses"
                    aria-selected="false"
                  >
                    My Addresses{" "}
                  </button>
                  {/* <!-- Second Tab Button --> */}

                  {/* <!-- Third Tab Button --> */}
                  <button
                    className="nav-link"
                    id="v-pills-Orders-tab"
                    data-toggle="pill"
                    data-target="#v-pills-Orders"
                    type="button"
                    role="tab"
                    aria-controls="v-pills-Orders"
                    aria-selected="false"
                  >
                    My Orders{" "}
                  </button>
                  {/* <!-- Third Tab Button --> */}

                  {/* <!-- Fourth Tab Button --> */}
                  <button
                    className="nav-link"
                    id="v-pills-method-tab"
                    data-toggle="pill"
                    data-target="#v-pills-method"
                    type="button"
                    role="tab"
                    aria-controls="v-pills-method"
                    aria-selected="false"
                  >
                    Payment Method{" "}
                  </button>
                  {/* <!-- Fourth Tab Button --> */}

                  {/* <!-- Logout Button --> */}
                  <button onClick={submitLogout} className="logout">
                    Logout
                  </button>
                  {/* <!-- Logout Button --> */}
                </div>
              </div>
            </div>
            <div className="col-lg-8">
              <div className="tab-content" id="v-pills-tabContent">
                {/* <!-- First Tab Content --> */}
                <div
                  className="tab-pane fade show active"
                  id="v-pills-Account"
                  role="tabpanel"
                  aria-labelledby="v-pills-Account-tab"
                >
                  <div
                    className={
                      editinfo ? "tab-box first-box hide" : "tab-box first-box"
                    }
                  >
                    <div className="persnol-information box">
                      <div className="heading">
                        <h3>Personal Information</h3>
                        <button
                          onClick={() => setEditinfo(true)}
                          className="editInformation edit"
                        >
                          <i className="fa fa-pencil" aria-hidden="true"></i>
                          <span>Edit</span>
                        </button>
                      </div>
                      <div className="row align-items-center mr-20">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>First Name</label>
                            <input
                              type="text"
                              value={user.first_name}
                              readOnly
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Last Name</label>
                            <input
                              type="text"
                              value={user.last_name}
                              readOnly
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Phone</label>
                            <input
                              type="tel"
                              value={user.phone}
                              readOnly
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Email</label>
                            <input
                              type="email"
                              value={user.email}
                              readOnly
                              className="form-control"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className={
                      editinfo
                        ? "tab-box second-box show"
                        : "tab-box second-box hide"
                    }
                  >
                    <div className="change-information box">
                      <div className="heading">
                        <h3>Edit Personal Infromation</h3>
                      </div>
                      <div className="row align-items-center mr-20">
                        <div className="col-md-6">
                          <MyInputFormGroup
                            placeholder="First name"
                            label="First name"
                            showLabel
                            value={fname}
                            onChange={(e) => setFname(e.target.value)}
                            errors={errors.first_name}
                          />
                        </div>
                        <div className="col-md-6">
                          <MyInputFormGroup
                            placeholder="Last name"
                            label="Last name"
                            showLabel
                            value={lname}
                            onChange={(e) => setLname(e.target.value)}
                            errors={errors.last_name}
                          />
                        </div>
                        <div className="col-md-6">
                          <MyInputFormGroup
                            placeholder="Phone"
                            label="Phone"
                            showLabel
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                            errors={errors.phone}
                            type="tel"
                          />
                        </div>
                        <div className="col-md-6">
                          <MyInputFormGroup
                            placeholder="Email"
                            label="Email"
                            showLabel
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            errors={errors.email}
                            type="email"
                            disabled
                          />
                        </div>
                        <div className="col-md-6">
                          <MyInputFormGroup
                            inputClassNames={["form-control"]}
                            placeholder="Gender"
                            label="Gender"
                            showLabel
                            value={gender}
                            onChange={(e) => setGender(e.target.value)}
                            errors={errors.gender}
                            type="select"
                            options={genderOptions}
                          />
                        </div>
                        <div className="col-md-12">
                          <MyInputFormGroup
                            placeholder="Avatar"
                            label="Avatar"
                            showLabel
                            onChange={(e) => setAvatar(e.target.files[0])}
                            errors={errors.avatar}
                            type="file"
                            accept="image/*"
                          />
                        </div>
                        <div className="col-md-6">
                          <div className="button-group">
                            <button
                              type="button"
                              onClick={submitUpdateProfile}
                              className="btn saveInformation"
                            >
                              Save
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="tab-box third-box hide">
                    <div className="change-pass box">
                      <div className="heading">
                        <h3>Change Password</h3>
                      </div>
                      <div className="row align-items-center mr-20">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Old Passwords</label>
                            <input
                              type="password"
                              // value="123456789"
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>New Passwords</label>
                            <input
                              type="password"
                              // value="123456789"
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Current Passwords</label>
                            <input
                              type="password"
                              // value="123456789"
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group mt-4">
                            <div className="button-group">
                              <button className="btn savePassword">
                                SAved
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <!-- First Tab Content --> */}

                {/* <!-- Second Tab Content --> */}
                <div
                  className="tab-pane fade"
                  id="v-pills-Addresses"
                  role="tabpanel"
                  aria-labelledby="v-pills-Addresses-tab"
                >
                  <div className="tab-box fourth-box">
                    <div
                      className={
                        editadd ? "my-address box hide" : "my-address box"
                      }
                    >
                      <div className="heading">
                        <h3>My Address</h3>
                      </div>
                      <div className="row align-items-center mr-20">
                        <div className="col-md-6">
                          <MyInputFormGroup
                            inputId="line_1"
                            label="Address"
                            showLabel
                            value={line1}
                            onChange={(e) => setLine1(e.target.value)}
                            errors={errors.line_1}
                          />
                        </div>
                        <div className="col-md-6">
                          <MyInputFormGroup
                            inputId="line_2"
                            label="Suite, Apartment, etc "
                            showLabel
                            value={line2}
                            onChange={(e) => setLine2(e.target.value)}
                            errors={errors.line_2}
                          />
                        </div>
                        <div className="col-md-3">
                          <MyInputFormGroup
                            inputId="city"
                            label="City"
                            showLabel
                            value={city}
                            onChange={(e) => setCity(e.target.value)}
                            errors={errors.city}
                          />
                        </div>
                        <div className="col-md-3">
                          <MyInputFormGroup
                            inputId="state"
                            label="State"
                            showLabel
                            value={state}
                            onChange={(e) => setState(e.target.value)}
                            errors={errors.state}
                          />
                        </div>
                        <div className="col-md-3">
                          <MyInputFormGroup
                            inputId="country"
                            label="Country"
                            showLabel
                            value={country}
                            onChange={(e) => setCountry(e.target.value)}
                            errors={errors.country}
                          />
                        </div>
                        <div className="col-md-3">
                          <MyInputFormGroup
                            inputId="zip"
                            label="Zip Code"
                            showLabel
                            value={zip}
                            onChange={(e) => setZip(e.target.value)}
                            errors={errors.zip}
                          />
                        </div>
                        <div className="col-md-6">
                          <MyInputFormGroup
                            inputClassNames={["form-control"]}
                            inputId="type"
                            label="Type"
                            showLabel
                            value={type}
                            onChange={(e) => setType(e.target.value)}
                            errors={errors.type}
                            type="select"
                            options={addressTypeOptions}
                          />
                        </div>
                        <div className="col-md-6">
                          <div className="button-group mt-3">
                            <button
                              className="btn addAddress"
                              type="button"
                              onClick={submitAddAddress}
                              disabled={spinner}
                            >
                              {spinner ? <MySpinner /> : "Add New Address"}
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="save-address">
                        <div className="heading">
                          <h3>Saved Addresses</h3>
                        </div>
                        {/* <!-- Saved Address --> */}
                        {addresses.map((singleAddress) => (
                          <label
                            className="container"
                            key={`address_${singleAddress.id}`}
                          >
                            <input
                              type="radio"
                              checked={singleAddress.is_default}
                              name="radio"
                              onChange={() =>
                                changeDefaultAddress(
                                  singleAddress.id,
                                  !singleAddress.is_defualt
                                )
                              }
                            />
                            <span className="checkmark">
                              <div className="select-address">
                                <div className="address-detail">
                                  <div className="actions">
                                    <button
                                      onClick={() => setEditadd(true)}
                                      className="editAddress edit"
                                    >
                                      <i
                                        className="fa fa-pencil-square-o"
                                        aria-hidden="true"
                                      ></i>
                                    </button>
                                    <button
                                      type="button"
                                      onClick={() =>
                                        submitDeleteAddress(singleAddress.id)
                                      }
                                    >
                                      <i
                                        className="fa fa-trash"
                                        aria-hidden="true"
                                      ></i>
                                    </button>
                                  </div>
                                  <h5>
                                    <span className="property">Country:</span>
                                    <div className="spacer"></div>
                                    <span className="value">
                                      {singleAddress.country}
                                    </span>
                                  </h5>
                                  <h5>
                                    <span className="property">Address:</span>
                                    <div className="spacer"></div>
                                    <span className="value">
                                      {singleAddress.line_1 +
                                        " " +
                                        (singleAddress.line_2 || "")}
                                      ,{singleAddress.zip}
                                    </span>
                                  </h5>
                                  {/* <h5>
                                    <span className="property">Phone:</span>
                                    <div className="spacer"></div>
                                    <span className="value">
                                      +44-123-456-789
                                    </span>
                                  </h5> */}
                                  <h5>
                                    <span className="property">City:</span>
                                    <div className="spacer"></div>
                                    <span className="value">
                                      {singleAddress.city}
                                    </span>
                                  </h5>
                                  <h5>
                                    <span className="property">State:</span>
                                    <div className="spacer"></div>
                                    <span className="value">
                                      {singleAddress.state}
                                    </span>
                                  </h5>
                                  <div className="deafult">
                                    <h5>Use as default</h5>
                                    <span className="dot"></span>
                                  </div>
                                </div>
                              </div>
                            </span>
                          </label>
                        ))}
                        {/* <!-- Saved Address --> */}
                      </div>
                    </div>
                  </div>
                  <div
                    className={
                      editadd
                        ? "tab-box fifth-box show"
                        : "tab-box fifth-box hide"
                    }
                  >
                    <div className="change-address box">
                      <div className="heading">
                        <h3>Edit Address</h3>
                      </div>
                      <div className="row align-items-center mr-20">
                        {/* <!-- <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label>First Name</label>
                                                        <input type="text" className="form-control" />
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label>Last Name</label>
                                                        <input type="text" className="form-control" />
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label>Phone</label>
                                                        <input type="tel" className="form-control" />
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label>Email</label>
                                                        <input type="email" className="form-control" />
                                                    </div>
                                                </div> --> */}
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Address</label>
                            <input type="text" className="form-control" />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Suite, Apartment, etc </label>
                            <input type="text" className="form-control" />
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <label>City</label>
                            <input type="text" className="form-control" />
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <label>State</label>
                            <input type="text" className="form-control" />
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <label>Country</label>
                            <input type="text" className="form-control" />
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <label>Zip Code</label>
                            <input type="text" className="form-control" />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="button-group mt-3">
                            <button
                              className="btn saveAddress"
                              onClick={() => setEditadd(false)}
                            >
                              Saved Address
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <!-- Second Tab Content --> */}

                {/* <!-- Third Tab Content --> */}
                <div
                  className="tab-pane fade"
                  id="v-pills-Orders"
                  role="tabpanel"
                  aria-labelledby="v-pills-Orders-tab"
                >
                  <div className="tab-box sixth-box">
                    <div className="donations box">
                      <div className="heading">
                        <h3>My Orders</h3>
                      </div>
                      <div className="donation-table">
                        <div className="table-reponsive">
                          <table className="table">
                            <thead>
                              <tr>
                                <th>OrderNo</th>
                                <th>Order Date</th>
                                <th>Payment Status</th>
                                <th>Total</th>
                                <th>Details</th>
                              </tr>
                            </thead>
                            <tbody>
                              {orders.map((order) => (
                                <tr key={`order_${order.id}`}>
                                  <td>#{order.id}</td>
                                  <td>
                                    {moment(order.created_at).format(
                                      "DD-MM-YYYY"
                                    )}
                                  </td>
                                  <td>
                                    <div className="status">
                                      <span>Paid</span>
                                    </div>
                                  </td>
                                  <td>${order.total}</td>
                                  <td>
                                    <button
                                      type="button"
                                      className="btn btn-primary"
                                      data-toggle="modal"
                                      data-target="#orderDetail"
                                      onClick={() => setOrderDetails(order)}
                                    >
                                      View Detail
                                    </button>
                                  </td>
                                </tr>
                              ))}
                              {/* <tr>
                                <td>#1084598423154</td>
                                <td>04-12-21</td>
                                <td>
                                  <div className="status">
                                    <span>Paid</span>
                                  </div>
                                </td>
                                <td>$590.00</td>
                                <td>
                                  <button
                                    type="button"
                                    className="btn btn-primary"
                                    data-toggle="modal"
                                    data-target="#orderDetail"
                                  >
                                    View Detail
                                  </button>
                                </td>
                              </tr>
                              <tr>
                                <td>#1084598423154</td>
                                <td>04-12-21</td>
                                <td>
                                  <div className="status">
                                    <span>Paid</span>
                                  </div>
                                </td>
                                <td>$590.00</td>
                                <td>
                                  <button
                                    type="button"
                                    className="btn btn-primary"
                                    data-toggle="modal"
                                    data-target="#orderDetail"
                                  >
                                    View Detail
                                  </button>
                                </td>
                              </tr>
                              <tr>
                                <td>#1084598423154</td>
                                <td>04-12-21</td>
                                <td>
                                  <div className="status">
                                    <span>Paid</span>
                                  </div>
                                </td>
                                <td>$590.00</td>
                                <td>
                                  <button
                                    type="button"
                                    className="btn btn-primary"
                                    data-toggle="modal"
                                    data-target="#orderDetail"
                                  >
                                    View Detail
                                  </button>
                                </td>
                              </tr>
                              <tr>
                                <td>#1084598423154</td>
                                <td>04-12-21</td>
                                <td>
                                  <div className="status">
                                    <span>Paid</span>
                                  </div>
                                </td>
                                <td>$590.00</td>
                                <td>
                                  <button
                                    type="button"
                                    className="btn btn-primary"
                                    data-toggle="modal"
                                    data-target="#orderDetail"
                                  >
                                    View Detail
                                  </button>
                                </td>
                              </tr> */}
                            </tbody>
                          </table>
                        </div>
                        {/* <div className="paginatio-count">
                          <ul className="pagination-list">
                            <li className="pagi arrow">
                              <a href="#">
                                <i
                                  className="fa fa-angle-left"
                                  aria-hidden="true"
                                ></i>
                              </a>
                            </li>
                            <li className="pagi">
                              <a href="#">1</a>
                            </li>
                            <li className="pagi active">
                              <a href="#">2</a>
                            </li>
                            <li className="pagi">
                              <a href="#">...</a>
                            </li>
                            <li className="pagi">
                              <a href="#">12</a>
                            </li>
                            <li className="pagi arrow">
                              <a href="#">
                                <i
                                  className="fa fa-angle-right"
                                  aria-hidden="true"
                                ></i>
                              </a>
                            </li>
                          </ul>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
                {/* <!-- Third Tab Content --> */}
                {/* <!-- Third Tab Content --> */}
                <div
                  className="tab-pane fade"
                  id="v-pills-method"
                  role="tabpanel"
                  aria-labelledby="v-pills-method-tab"
                >
                  <div className="tab-box sixth-box">
                    <div className="method box">
                      <div className="heading">
                        <h3>Payment Methods</h3>
                      </div>
                      <div className="payments-method-box">
                        <div className="add-new-method">
                          <div className="form-group">
                            <CardElement
                              options={{
                                style: {
                                  base: { fontSize: "20px" },
                                  complete: {
                                    color: config.primaryColor,
                                  },
                                },
                              }}
                            />
                          </div>
                          <button
                            type="button"
                            className="primary-btn"
                            onClick={addCard}
                            disabled={spinner}
                          >
                            {spinner ? <MySpinner /> : "Add Card"}
                          </button>
                        </div>
                        <div className="saved-cards">
                          {paymentMethods.map((pm) => (
                            <label
                              className={
                                "pymnt-container " +
                                (!pm.is_default ? "second-card" : "")
                              }
                              key={`payment_method_${pm.id}`}
                            >
                              <input
                                type="radio"
                                checked={pm.is_default}
                                name="payment-radio"
                                hidden
                                onChange={() =>
                                  updateCard(pm.id, !pm.is_default)
                                }
                              />
                              <span className="checkmark">
                                <div className="card-details">
                                  <div className="radio-btn">
                                    <span></span>
                                  </div>
                                  <div className="visa-card">
                                    <div className="img-box">
                                      <figure>
                                        <img
                                          style={{ width: 75, height: 45 }}
                                          src={config.returnCardImage(
                                            pm.card_brand
                                          )}
                                          alt="No Image Found"
                                        />
                                      </figure>
                                    </div>
                                    <div className="input-box">
                                      <label htmlFor="card-number">
                                        {pm.card_brand}
                                      </label>
                                      <br />
                                      <input
                                        id="card-number"
                                        value={"**********" + pm.last_4}
                                        type="text"
                                        readOnly
                                      />
                                    </div>
                                  </div>
                                  <div className="extra-btn">
                                    <button className="btn default disabled">
                                      Default
                                    </button>
                                    <div className="selection">
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                    </div>
                                  </div>
                                  {/* {pm.is_default ? (
                                    <div className="extra-btn">
                                      <button className="btn default disabled">
                                        Default
                                      </button>
                                      <div className="selection">
                                        <i
                                          className="fa fa-check"
                                          aria-hidden="true"
                                        ></i>
                                      </div>
                                    </div>
                                  ) : null} */}
                                </div>
                              </span>
                            </label>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <!-- Third Tab Content --> */}
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Profile Sec End Here --> */}

      {/* <!-- My Order Popup --> */}
      <div
        className="modal fade"
        id="orderDetail"
        tabIndex="-1"
        aria-labelledby="orderDetailLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="btn-close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="26"
                  height="26"
                  fill="currentColor"
                  className="bi bi-x"
                  viewBox="0 0 16 16"
                >
                  <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                </svg>
              </button>
            </div>
            <div className="modal-body">
              <div className="heading">
                <h2>Order Details</h2>
              </div>
              <div className="all-orders">
                {orderDetails?.items?.map((orderItem) => (
                  <div className="order-detail">
                    <div className="img-box">
                      <figure>
                        <img
                          src={config.returnImage(orderItem?.product?.cover)}
                          alt=""
                          className="img-fluid"
                        />
                      </figure>
                    </div>
                    <div className="details">
                      <h5 className="name">{orderItem.product?.name}</h5>
                      <h5 className="price">
                        <span className="pro-name">
                          Price: ${config.numberFormat(orderItem?.subtotal)}
                        </span>
                        <span className="order-no">
                          order no: #{orderDetails.id}
                        </span>
                      </h5>
                      <h5 className="status">
                        <span>
                          <label>Quantity</label>({orderItem.quantity})
                        </span>
                        <span>Paid</span>
                      </h5>
                    </div>
                  </div>
                ))}
              </div>
              <ul className="order-summary">
                <li>
                  <span>Sub Total :</span>
                  <span>${config.numberFormat(orderDetails?.subtotal)}</span>
                </li>
                {/* <li>
                  <span>Disscount Code Applied :</span>
                  <span>-$ 14.00</span>
                </li> */}
                {/* <li>
                  <span>Shipping Cost :</span>
                  <span>${config.numberFormat(orderDetails.subtotal)}</span>
                </li> */}
                <li>
                  <span>Total Amount :</span>
                  <span>${config.numberFormat(orderDetails?.total)}</span>
                </li>
              </ul>
              <div className="button-group">
                <button
                  data-dismiss="modal"
                  onClick={() => navigate("/shop")}
                  className="btn"
                >
                  Continue Shopping
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- My Order Popup --> */}

      {/* Footer Start Here */}
      <Footer />
      {/* Footer End Here */}
    </>
  );
};

// const mapStateToProps = (state) => ({
//   user: state.auth.user,
//   addresses: state.addresses,
// });

// const mapDispatchToProps = (dispatch) => ({
//   getAdddresses: async () => dispatch(await GetAddressesAction()),
//   addAddress: async (address) => dispatch(await AddAddressAction(address)),
//   updateAddress: async (id, address) =>
//     dispatch(await UpdateAddressAction(id, address)),
//   updateProfile: async (user) => dispatch(await UpdateProfileAction(user)),
//   deleteAddress: async (id) => dispatch(await DeleteAddressAction(id)),
//   logout: () => dispatch(LogoutAction()),
// });

const Profile = () => {
  return (
    <Elements stripe={loadStripe(process.env.REACT_APP_STRIPE_PK)}>
      <ProfileInner />
    </Elements>
  );
};

export default Profile;
