import React, { useState } from "react";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import InnerBanner from "../../components/InnerBanner/InnerBanner";
import Newsletter from "../../components/Newsletter/Newsletter";
import { ContactBanner } from "../../constant/Index";
import "../../assets/css/contact.css";
import Faqs from "../../components/Faqs/Faqs";
import config from "../../config";
import { CONTACT_ENDPOINT } from "../../endpoints";
import MySpinner from "../../components/MySpinner";

const Contact = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [company, setCompany] = useState("");
  const [location, setLocation] = useState("");
  const [message, setMessage] = useState("");

  const [spinner, setSpinner] = useState(false);

  const onSubmit = async () => {
    try {
      setSpinner(true);
      await config.postApi(
        CONTACT_ENDPOINT,
        config.defaultHeaders,
        JSON.stringify({
          name,
          email,
          phone,
          company,
          location,
          message,
          type: "Contact us",
        })
      );
      setSpinner(false);
    } catch (e) {
      setSpinner(false);
      console.log(e);
    }
  };

  return (
    <>
      {/* Header Start Here */}
      <Header />
      {/* Header End Here */}

      {/* InnerBanner Sec Start Here */}
      <InnerBanner
        PageName="Get in touch with us today"
        BannerImage={ContactBanner}
      />
      {/* InnerBanner Sec End Here */}

      {/* Contact Sec Start Here */}
      <section className="contact_sec">
        <div className="container">
          <div className="contact-box">
            <div className="row">
              <div className="col-lg-5">
                <div className="contact-info">
                  <ul className="info">
                    <li>
                      <a href="mailto:info@infectionprevention.co.nz">
                        info@infectionprevention.co.nz
                      </a>
                    </li>
                    <li>
                      <a href="tel:0800849211">0800 849 211</a>
                    </li>
                    <li>
                      <a href="https://goo.gl/maps/v27kXpg9SwwmZQ4a7">
                        BCP Trust: PO Box 42024, Tower Junction, Christchurch,
                        New Zealand 8149
                      </a>
                    </li>
                    <li className="social-icons">
                      <a href="#">
                        <i
                          className="fa fa-facebook-official"
                          aria-hidden="true"
                        ></i>
                      </a>
                      <span className="seprator"></span>
                      <a href="#">
                        <i
                          className="fa fa-linkedin-square"
                          aria-hidden="true"
                        ></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-7">
                <form action="">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <input
                          type="text"
                          placeholder="Full Name"
                          required
                          className="form-control"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <input
                          type="email"
                          placeholder="Email Address"
                          required
                          className="form-control"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <input
                          type="text"
                          placeholder="Phone Number"
                          required
                          className="form-control"
                          value={phone}
                          onChange={(e) => setPhone(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <input
                          type="text"
                          placeholder="Company Name (optional)"
                          className="form-control"
                          value={company}
                          onChange={(e) => setCompany(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group">
                        <input
                          type="text"
                          placeholder="Location"
                          required
                          className="form-control"
                          value={location}
                          onChange={(e) => setLocation(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group">
                        <textarea
                          placeholder="Message"
                          className="form-control"
                          value={message}
                          onChange={(e) => setMessage(e.target.value)}
                        ></textarea>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="button-group">
                        <button
                          className="primary-btn"
                          type="button"
                          onClick={onSubmit}
                          disabled={spinner}
                        >
                          {spinner ? <MySpinner /> : "Submit"}
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Contact Sec End Here */}

      {/* Faqs Sec Start Here */}
      <section className="faqs_sec">
        <div className="container">
          <Faqs />
        </div>
      </section>
      {/* Faqs Sec End Here */}

      {/* Newsletter Sec Start Here */}
      <Newsletter />
      {/* Newsletter Sec End Here */}

      {/* Footer Start Here */}
      <Footer />
      {/* Footer End Here */}
    </>
  );
};

export default Contact;
