class FormException {
  errors = [];

  constructor(errors) {
    this.errors = errors;
  }

  get = (field) => this.errors[field];
}

export default FormException;
