import { LOGIN, LOGOUT, UPDATE_PROFILE } from "../actions/types";

const initialState = {
  user: {},
  token: null,
};

const auth = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN:
      return action.payload;
    case UPDATE_PROFILE:
      return { ...state, user: action.payload };
    case LOGOUT:
      return {
        user: {},
        token: null,
      };
    default:
      return state;
  }
};

export default auth;
