import React from "react";
import { Link } from "react-router-dom";

const MenuItem = ({
  hasChild = false,
  title,
  slug,
  submenus = [],
  hasParentCategory = false,
}) => {
  if (hasChild) {
    return (
      <li className="inner-dropdown">
        <Link to={`/category/${slug}`} className="dropdown-item">
          {title}
        </Link>
        <ul className="dropdown-innermenu">
          {submenus.map((singleSubMenu) => (
            <MenuItem
              key={`menu_${singleSubMenu.id}`}
              hasChild={
                singleSubMenu.child_categories &&
                singleSubMenu.child_categories.length > 0
              }
              title={singleSubMenu.name}
              slug={singleSubMenu.slug}
              submenus={singleSubMenu.child_categories}
              hasParentCategory={true}
            />
          ))}
        </ul>
      </li>
    );
  }
  return (
    <li>
      <Link
        className={hasParentCategory ? "menu-links" : "dropdown-item"}
        to={`/category/${slug}`}
      >
        {title}
      </Link>
    </li>
  );
};

export default MenuItem;
