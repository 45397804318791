import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Blog from "../../components/Blog/Blog";
import Brands from "../../components/Brands/Brands";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import Newsletter from "../../components/Newsletter/Newsletter";
import RangeProducts from "../../components/RangeProducts/RangeProducts";
import config from "../../config";
import { BLOGS_ENDPOINT } from "../../endpoints";
import Banner from "./Banner";
import Ipc from "./Ipc";

const Home = () => {
  const [blogs, setBlogs] = useState([]);

  useEffect(() => {
    config.getApi(BLOGS_ENDPOINT, config.defaultHeaders).then((blogs) => {
      setBlogs(blogs);
    });
  }, []);

  return (
    <>
      {/* Header Start Here */}
      <Header />
      {/* Header Start End */}

      {/* Banner Sec Start Here */}
      <Banner />
      {/* Banner Sec End Here */}

      {/* Range Product Sec Start Here */}
      <section className="range-product">
        <div className="container">
          <div className="heading-wrapper wow animate__animated animate__fadeInDown">
            <h2>
              Our range of products help Aged Care and Home Care manage
              Infection Prevention and Control easily
            </h2>
            <h4>Click through to book an enquiry</h4>
          </div>
          <div className="row">
            <RangeProducts />
          </div>
        </div>
      </section>
      {/* Range Product Sec End Here */}

      {/* IPC Sec Start Here */}
      <Ipc />
      {/* IPC Sec End Here */}

      {/* Brand Sec Start Here */}
      <Brands />
      {/* Brand Sec End Here */}

      {/* Blog Sec Start Here */}
      <section className="blog_sec">
        <div className="container">
          <div className="heading-wrapper">
            <h3 className="wow animate__animated animate__fadeIn">
              Discover helpful information and thought provoking content in the
              Bug Control Blog
            </h3>
            <div className="button-group wow animate__animated animate__fadeIn animate__delay-1s">
              <Link to="/news" className="primary-btn">
                View All
              </Link>
            </div>
          </div>
          <Blog blogData={blogs} />
        </div>
      </section>
      {/* Blog Sec End Here */}

      {/* Newsletter Sec Start Here */}
      <Newsletter />
      {/* Newsletter Sec End Here */}

      {/* Footer Start Here */}
      <Footer />
      {/* Footer Start Here */}
    </>
  );
};

export default Home;
