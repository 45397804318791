import {
  CardElement,
  Elements,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import React, { useState } from "react";
import Swal from "sweetalert2";
import config from "../config";
import MySpinner from "./MySpinner";

const AddCardInner = ({ onAdded }) => {
  const elements = useElements();
  const stripe = useStripe();

  const [spinner, setSpinner] = useState(false);

  const addCard = async () => {
    setSpinner(true);
    const { error, source } = await stripe.createSource(
      elements.getElement(CardElement),
      {
        type: "card",
      }
    );
    if (error) {
      setSpinner(false);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: error.message,
      });
      return false;
    }
    setSpinner(false);
    onAdded(source.id);
  };

  return (
    <div>
      <div className="form-group">
        <CardElement
          options={{
            style: {
              base: { fontSize: "20px" },
              complete: {
                color: config.primaryColor,
              },
            },
          }}
        />
      </div>
      <button type="button" onClick={addCard} disabled={spinner}>
        {spinner ? <MySpinner /> : "Add Card"}
      </button>
    </div>
  );
};

const AddCard = ({ onAdded }) => {
  return (
    <Elements stripe={loadStripe(process.env.REACT_APP_STRIPE_PK)}>
      <AddCardInner onAdded={onAdded} />
    </Elements>
  );
};

export default AddCard;
