import React from "react";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import InnerBanner from "../../components/InnerBanner/InnerBanner";
import Newsletter from "../../components/Newsletter/Newsletter";
import { LegalBanner } from "../../constant/Index";

const TermsConditions = () => {
  const data = `<div className="legal_content">
    <h2>TERMS AND CONDITIONS OF USE</h2>
<h3>1. Application</h3>
<p>1.1 Bug Control (Aust) Pty Ltd (ABN 96 070 872 383) (Bug Control, we, us or our) operates a website, infectioncontrol.care, that enables customers to order goods and receive services from Bug Control.</p>
<p>1.2 These terms and conditions (Terms) apply to any use of our website, whether as a guest or as a registered user. Use of our website includes accessing, browsing, or registering to use our website.</p>
<p>1.3 By using our website, you confirm that you accept these Terms and agree to comply with them.</p>
<h3>2. Your Account and Password</h3>
<p>2.1 If you are a registered user of our website you are responsible for keeping your password secret and secure.</p>
<p>2.2 You must not disclose your password or allow any other person to use your login information to access our website.</p>
<h3>3. Emails</h3>
<p>3.1 You agree that we may send you emails relating to your registration on our website, transactions you undertake through our website and for promoting our services.</p>
<h3>4. Third Party Websites and Hyperlinking</h3>
<p>4.1 Our website may contain links to other websites. These links are provided for your information only and you acknowledge that we have no control over the content of those websites. By using our website, you expressly relieve us from any and all liability arising from your use of any third-party website.</p>
<h3>5. Intellectual Property</h3>
<p>5.1 We own all intellectual property rights in our website (except content uploaded directly by users of our website). You must not use or distribute our intellectual property without our prior written consent.</p>
<h3>6. Disclaimer</h3>
<p>6.1 We do not warrant that:</p>
<p>Any information provided on our website is free from errors or reliable;</p>
<p>Our website, or any content on it, will always be available or be uninterrupted. Access to our website is permitted on a temporary basis. We may suspend, withdraw, discontinue or change all or any part of our website without notice;</p>
<p>Our website will be secure or free from bugs or viruses.</p>
<h3>7. Liability</h3>
<p>7.1 We will not be liable:</p>
<p>If our website is unavailable at any time;</p>
<p>For any corruption of data or information held by us;</p>
<p>To the fullest extent permitted by law for any loss (including, without limitation, consequential loss, loss of profits or loss of data), damage, cost or expense of any kind suffered or incurred in connection with your use of our website.</p>
<h3>8. User’s Suspension of Registration</h3>
<p>8.1 You may request that we suspend your account and remove your profile from our website at any time provided you have no current bookings or subscriptions. However, you acknowledge that we may retain your profile information for our records.</p>
<h3>9. Termination of Registration</h3>
<p>9.1 We reserve the right in our sole discretion to decline to register you as a user of our website or terminate your registration. Without limiting that right, we may terminate your registration if we receive any complaints about you, you breach our terms and conditions or you create an account using false information.</p>
<h3>10. Miscellaneous</h3>
<p>10.1 You acknowledge that we may collect, use and disclose personal information about you in accordance with our privacy policy, which forms part of these Terms.</p>
<p>10.2 We may vary these Terms from time to time. We will provide notice of the change by uploading the updated Terms on our website and we will also attempt to email you a copy of the updated Terms (if you have provided us with your email address). You should periodically review the most up to date Terms on our website. After uploading the updated Terms on our website you will be deemed to have accepted those updated Terms if you continue to use our website or the services provided by us.</p>
<p>10.3 If any provision in these Terms is held to be invalid, illegal or unenforceable, this will not affect the validity of any other provision in these Terms.</p>
<p>10.4 The law of the State of New South Wales, Australia applies to these Terms and the transactions contemplated by them, and you agree to submit to the non-exclusive jurisdiction of the Courts and Tribunals of that State.</p>
<h2>TERMS OF TRADE</h2>
<h3>1. Definitions</h3>
<p>1.1 The following definitions apply in these Terms:<br>
Australian Consumer Law means Schedule 2 of the Competition and Consumer Act 2010 (Cth) as amended from time to time).</p>
<p>Bug Control means Bug Control (Aust) Pty Ltd (ABN 96 070 872 383),.</p>
<p>Customer means the customer that Bug Control supplies Products to under these Terms.</p>
<p>GST has the same meaning as that word is given in the A New Tax System (Goods and Services Tax) Act 1999 (Cth) (as amended from time to time).</p>
<p>Intellectual Property means all intellectual property, including (but not limited to) copyright, reports, software, trade marks, or any other intellectual property prepared or created by Bug Control in relation to the Products.</p>
<p>Products means products supplied by Bug Control to the Customer.</p>
<p>Terms means these Terms of Trade.</p>
<p>Website means Bug Control’s website at https://infectioncontrol.care/.</p>
<h3>2. Application and Acceptance</h3>
<p>2.1 These Terms, together with Bug Control’s Privacy Policy and Website Terms of Use apply to Bug Control’s supply of Products to the Customer, except to the extent that Bug Control and the Customer agree otherwise in writing.</p>
<p>2.2 The Customer will be deemed to have accepted these Terms by clicking the checkbox which confirms their acceptance of these Terms when ordering Products from Bug Control.</p>
<h3>3. Orders</h3>
<p>3.1 The Customer may order Products from Bug Control by placing an order on the Website, or as otherwise agreed with Bug Control.</p>
<p>3.2 An agreement relating to the Products will only be formed when the Customer receives a confirmation email from Bug Control accepting the Customer’s order.</p>
<p>3.3 If an order is not accepted by Bug Control, Bug Control will notify the Customer by telephone or email to arrange a full refund of any payment made by the Customer to be processed.</p>
<p>3.4 The Website is solely for the promotion of Bug Control’s Products in Australia. Bug Control does not accept orders from, or deliver to, addresses outside of Australia.</p>
<h3>4. Price</h3>
<p>4.1 The prices for the Products are listed on the Website.</p>
<p>4.2 All prices are in Australian dollars and are inclusive of GST.</p>
<p>4.3 No delivery fee is payable in respect of Products ordered in accordance with these Terms.</p>
<h3>5. Payment</h3>
<p>5.1 Unless otherwise agreed to by Bug Control, the Customer must make payment in full on the Website at the time the Customer makes an order.</p>
<p>5.2 Bug Control will only accept payment via the third-party payment provider (i.e. PayPal) made available through the Website, unless otherwise agreed with the Customer.</p>
<h3>6. Delivery and Risk</h3>
<p>6.1 Bug Control will endeavour to dispatch the Products to the physical address supplied by the Customer as soon as possible once the order is completed.</p>
<p>6.2 If Bug Control provides the Customer with an estimated delivery date, Bug Control will not be liable for any loss or damage suffered by the Customer or any other person for failure to deliver the Products by that date.</p>
<p>6.3 Risk of any loss or damage to the Products passes to the Customer once the Products have been delivered to the address supplied by the Customer.</p>
<h3>7. Intellectual Property</h3>
<p>7.1 The Customer agrees that all Intellectual Property shall be and remain owned by Bug Control at all times.</p>
<p>7.2 The Customer must not, and must not to attempt or assist, authorise, enable or permit anyone else, to:<br>
copy or adapt the Products; or<br>
redistribute or resupply the Products.</p>
<h3>8. Liability</h3>
<p>8.1 The Customer agrees that it is a acquiring the Products for resupply or use in their Business in providing goods and service to third parties..</p>
<p>8.2 If, notwithstanding clause 1 the Customer is a consumer under the Australian Consumer Law, then the Customer may have rights under that law or other rights in relation to the supply of Goods and Services that cannot lawfully be excluded by Bug Control (Non-excludable Rights). With the exception of Non-excludable Rights, and notwithstanding any other provision of these Terms, the liability of Bug Control to the Customer, whether arising under or in connection with these Terms or the performance or non-performance thereof or anything incidental thereto, and whether by way of indemnity, by statute (to the extent that it is possible to exclude such liability), in tort (for negligence or otherwise), or on any other basis in law or equity is hereby limited and excluded as follows:<br>
Bug Control shall have no liability whatsoever to the Customer for loss of use, production, profit, revenue, business, data, contract or anticipated saving, or for any financing costs or increase in operating costs or any economic loss or for any special, indirect or consequential loss or damage; and<br>
the total aggregate liability of the Supplier is at all times limited to the amount determined under clause 2.</p>
<p>8.3 If Bug Control is liable to the Customer for any reason then to the maximum extent permitted by law, Bug Control’s liability for breach of these Terms or any law (including any Non-Excludable Right) will be limited, at Bug Control’s discretion, to:<br>
re-supply of the Products or the supply of any equivalent Products;<br>
repair of the Products;<br>
an amount equal to the price paid by the Customer for the Products.</p>
<p>8.4 Bug Control will not be liable for any loss of profits or any indirect or consequential loss or damage arising out of these Terms or Bug Control’s dealings with the Customer.</p>
<p>8.5 The parties acknowledge that Bug Control may update the content of the Products from time to time, in particular to reflect current standards and legislation. Bug Control will not be liable to the Customer in relation to the Customer’s use of non-current versions of any content which has previously formed part of the Products.</p>
<h3>9. Events Beyond Bug Control’s Control</h3>
<p>9.1 Bug Control reserves the right to defer the date of delivery or to cancel the Customer’s order if Bug Control is prevented from or delayed in the carrying on of Bug Control’s business due to circumstances beyond Bug Control’s reasonable control.</p>
<h3>10. Variations to these Terms</h3>
<p>10.1 Bug Control reserves the right to make changes to these Terms, the descriptions of the Products, and the associated prices. Bug Control will provide notice of the change by uploading the updated Terms or publishing the new descriptions of the Products or prices, on the Website. Any changes will apply to all orders made after that time and the Customer will be required to accept those new terms when placing an order.</p>
<h3>11. No Waiver</h3>
<p>11.1 Bug Control will not be deemed to have waived any right under these Terms unless the waiver is in writing and signed by Bug Control. A failure to exercise or delay in exercising any right by Bug Control under these Terms will not operate as a waiver of that right. Any such waiver will not constitute a waiver of any subsequent or continuing right or of any other provision in these Terms.</p>
<h3>12. No Assignment</h3>
<p>12.1 The Customer may not assign or transfer any of its rights or obligations under these Terms without Bug Control’s prior written consent.</p>
<p>12.2 Bug Control may assign, novate or transfer its rights under these Terms at its sole discretion and without the Customer’s prior cons</p>
<h3>13. Entire Agreement</h3>
<p>13.1 These Terms, including the Privacy Policy and Website Terms and Conditions, constitute the entire agreement and understanding between the parties. These Terms supersede and replace any and all prior oral or written understandings or agreements between Bug Control and the Customer regarding the provision of Products contemplated by these Terms.</p>
<h3>14. Governing Law and Jurisdiction</h3>
<p>14.1 The laws of the State of New South Wales will apply to these Terms and the Courts and Tribunals of that State will have jurisdiction in relation to these Terms and any claims arising out of any transaction between the Customer and Bug Control.</p>
<p>14.2 The provisions of the United Nations Convention on the Contracts for the International of Sale of Goods 1980 are expressly excluded from these Conditions.</p>
</div>`;
  return (
    <>
      {/* Header Start Here */}
      <Header />
      {/* Header End Here */}

      {/* InnerBanner Sec Start Here */}
      <InnerBanner
        PageName="Infection Prevention Legal Information"
        BannerImage={LegalBanner}
      />
      {/* InnerBanner Sec End Here */}

      {/* Terms & Condition Sec Start Here */}
      <section className="terms_condition">
        <div className="container">
          <div dangerouslySetInnerHTML={{ __html: data }}></div>
        </div>
      </section>
      {/* Terms & Condition Sec End Here */}

      {/* Newsletter Sec Start Here */}
      <Newsletter />
      {/* Newsletter Sec End Here */}

      {/* Footer Start Here */}
      <Footer />
      {/* Footer End Here */}
    </>
  );
};

export default TermsConditions;
