import React from 'react'
import { Link } from 'react-router-dom'
import Footer from '../../components/Footer/Footer'
import Header from '../../components/Header/Header'
import InnerBanner from '../../components/InnerBanner/InnerBanner'
import { ApproachImg, Icon1, Icon2, InnerBannerImage, PurposeImg, StoryImg } from '../../constant/Index'
import "../../assets/css/about.css";
import Brands from '../../components/Brands/Brands'
import Newsletter from '../../components/Newsletter/Newsletter'

const About = () => {
    return (
        <>
            {/* Header Start Here */}
            <Header />
            {/* Header End Here */}

            {/* InnerBanner Sec Start Here */}
            <InnerBanner PageName="About Us" BannerImage={InnerBannerImage} />
            {/* InnerBanner Sec End Here */}

            {/* Our Story Sec Start Here */}
            <section className="our_story">
                <div className="container">
                    <div className="row reversed-row">
                        <div className="col-lg-7">
                            <div className="content-wrapper wow animate__animated animate__fadeInLeft">
                                <div className="heading-wrapper">
                                    <h5>our-story</h5>
                                    <h2>A legacy of infection prevention</h2>
                                </div>
                                <p>
                                    Every product that Bug Control develops has your Facility, workforce, and the care of residents at its core. That approach to our business started 27 years ago, on day one, and continues to inform our processes and drive our innovations today.<br /><br />
                                    Established in 1995 by Judith Forrest, led today by our CEO Lyndon Forrest and General Manager Cara Kelly, our mission has never changed, we’re here to stop infections.<br /><br />
                                    Bug Control is trusted as Australia and New Zealand’s leading provider of Aged Care Infection Prevention and Control products and services.<br /><br />
                                    In 2019, Bug Control was recognised as one of the <span className="colored-txt">top fifty companies in healthcare globally at the IFAH conference</span> for our innovations in healthcare
                                </p>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="quality-box">
                                        <div className="icon-box">
                                            <figure><img src={Icon1} alt="icon-image" className="img-fluid" /></figure>
                                        </div>
                                        <div className="content">
                                            <h4 className="title">Get access</h4>
                                            <p>
                                                Subscribe to Bug Control to access our Knowledge Base and Policies & Procedures.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="quality-box">
                                        <div className="icon-box">
                                            <figure><img src={Icon2} alt="icon-image" className="img-fluid" /></figure>
                                        </div>
                                        <div className="content">
                                            <h4 className="title">Ensure your IPC plan is robust</h4>
                                            <p>
                                                Let Bug Control help you ensure your IPC management exceeds the Aged Care Quality Standards.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="button-group mt-4">
                                <Link to="/packages" className='primary-btn'>Subscription</Link>
                            </div>
                        </div>
                        <div className="col-lg-5">
                            <div className="img-box wow animate__animated animate__fadeInRight">
                                <figure><img src={StoryImg} alt="story-image" className="img-fluid" /></figure>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* Our Story Sec End Here */}

            {/* Our Purpose Sec Start Here */}
            <section className="our_purpose">
                <div className="container">
                    <div className="img-box wow animate__animated animate__zoomIn">
                        <figure><img src={PurposeImg} alt="purpose-image" className="img-fluid" /></figure>
                    </div>
                    <div className="content-wrapper text-center wow animate__animated animate__zoomInDown">
                        <h2>Our Purpose</h2>
                        <p>
                            We believe in technological innovation and positive collaboration, working in partnership with our clients to help them implement and sustain best-practice Infection Prevention and Control (IPC) policies and procedures. Our innovative suite of tech-native products ensures Bug Control Subscribers are serviced and supported. Partnering with Bug Control delivers solutions across IPC planning, products, compliance audits, education and more, everything necessary for the elimination of infection risk in your Aged Care Facility.<br /><br />
                            Bug Control provides evidence-based and comprehensive IPC resources. Our digital information is regularly updated to meet changing Aged Care Quality Standards, including our subscription-accessed Knowledge Base, relied on by thousands of Aged Care Facilities in their day-to-day procedures.<br /><br />
                            Want to have a chat about enhancing your Aged Care Facility’s Infection Prevention and Control? Please book a FREE 20-minute consultation, where you’ll speak with an experienced member of our friendly team to gain insightful recommendations that will help you protect the vulnerable people in your care.
                        </p>
                        <div className="button-group mt-5">
                            <Link to="/contact-us" className='primary-btn'>Contact</Link>
                        </div>
                    </div>
                </div>
            </section>
            {/* Our Purpose Sec End Here */}

            {/* Our Purpose Sec Start Here */}
            <section className="our_purpose approach_sec">
                <div className="container">
                    <div className="img-box wow animate__animated animate__zoomIn">
                        <figure><img src={ApproachImg} alt="purpose-image" className="img-fluid" /></figure>
                    </div>
                    <div className="content-wrapper text-center wow animate__animated animate__zoomInDown">
                        <h2>Sustaining Our Approach Through Technological Innovation</h2>
                        <p>
                            Our approach to Infection Prevention training and workforce compliance is aligned with the demands of the workplace and designed to be information-rich and easily navigated, leaving IP Leads with certification to empower them to meet the Aged Care Quality Standards.<br /><br />
                            Our technological innovation extends into IP Surveillance, with a groundbreaking mobile application developed in partnership with IPAC Canada. Available on iOS and Android, the surveillance tool is the impetus for significant quality improvement.
                        </p>
                        <div className="button-group mt-5">
                            <Link to="/bc-systems" className='primary-btn'>Discover</Link>
                        </div>
                    </div>
                </div>
            </section>
            {/* Our Purpose Sec End Here */}

            {/* Brand Sec Start Here */}
            <Brands />
            {/* Brand Sec End Here */}

            {/* Newsletter Sec Start Here */}
            <Newsletter />
            {/* Newsletter Sec End Here */}

            {/* Footer Sec Start Here */}
            <Footer />
            {/* Footer Sec End Here */}
        </>
    )
}

export default About
