import React from 'react'
import Footer from '../components/Footer/Footer'
import Header from '../components/Header/Header'
import { NotFoundImg } from '../constant/Index'

const NotFound = () => {
    return (
        <>
            {/* Header Start Here */}
            <Header />
            {/* Header End Here */}

            {/* 404 Sec Start Here */}
            <section className="not-found">
                <div className="container">
                    <div className="img-box">
                        <figure><img src={NotFoundImg} alt="not-found" /></figure>
                    </div>
                </div>
            </section>
            {/* 404 Sec end Here */}

            {/* Footer Start Here */}
            <Footer />
            {/* Footer End Here */}
        </>
    )
}

export default NotFound
