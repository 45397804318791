import { GET_CATEGORIES } from "./types";
import config from "../../config";
import { CATEGORIES_ENDPOINT } from "../../endpoints";

export const GetCategoriesAction = () => async (dispatch, getState) => {
  const headers = config.defaultHeaders;
  const res = await config.getApi(CATEGORIES_ENDPOINT, headers);
  if (res) {
    dispatch({ type: GET_CATEGORIES, payload: res });
  }
};
