import React, { useState } from "react";
import { Link } from "react-router-dom";
import MyInput from "../../components/MyInput";
import MySpinner from "../../components/MySpinner";
import config from "../../config";
import { Logo } from "../../constant/Index";
import { SEND_RESET_EMAIL_ENDPOINT } from "../../endpoints";
import FormException from "../../exceptions/FormException";

const ForgotPass = () => {
  const [email, setEmail] = useState("");

  const [spinner, setSpinner] = useState(false);
  const [errors, setErrors] = useState({});

  const submit = async () => {
    try {
      setSpinner(true);
      const data = JSON.stringify({ email });
      await config.postApi(
        SEND_RESET_EMAIL_ENDPOINT,
        config.defaultHeaders,
        data
      );
      setSpinner(false);
      setErrors({});
    } catch (e) {
      setSpinner(false);
      if (e instanceof FormException) {
        setErrors(e.errors);
      } else {
        console.log(e);
      }
    }
  };

  return (
    <>
      {/* Login Sec Start Here */}
      <section className="auth login">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-7 col-md-6 px-0">
              <div className="row mx-0 h-100">
                <div className="col-xl-6 col-lg-10 col-md-10 col-sm-8 mx-auto">
                  <div className="auth_start">
                    <div className="logo">
                      <Link to="/">
                        <img src={Logo} alt="logo" className="img-fluid" />
                      </Link>
                    </div>
                    <div className="wraper">
                      <div className="top_text">
                        <h2>Forgot Password</h2>
                        <p>
                          Enter You Email Address We Will Send You Reset Link On
                          Your Email
                        </p>
                      </div>
                      <div className="form_start mt-5">
                        <MyInput
                          placeholder="Email"
                          inputId="email"
                          type="email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          errors={errors.email}
                        />
                        <div className="form_row mt-3">
                          <button
                            className="primary-btn center d-block w-100"
                            type="button"
                            onClick={submit}
                            disabled={spinner}
                          >
                            {spinner ? <MySpinner /> : "Submit"}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-5 col-md-6 px-0">
              <div className="side-image">
                <div className="main_img">
                  <Link to="/" className="d-block">
                    <img src={Logo} className="img-fluid" alt="" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Login Sec End Here */}
    </>
  );
};

export default ForgotPass;
