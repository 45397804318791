import React, { useEffect, useState } from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import InnerBanner from "../../components/InnerBanner/InnerBanner";
import Newsletter from "../../components/Newsletter/Newsletter";
import "../../assets/css/packages.css";
import { Link, useNavigate } from "react-router-dom";
import { InnerBannerImage } from "../../constant/Index";
import Package from "../../components/Package";
import { connect } from "react-redux";
import { GetPackagesAction } from "../../redux/actions/packages";
import OverlaySpinner from "../../components/OverlaySpinner";
import config from "../../config";
import { SUBSCRIPTIONS_ENDPOINT } from "../../endpoints";
import { UPDATE_PROFILE } from "../../redux/actions/types";

const Packages = ({ packages, getPackages, auth, updateProfile }) => {
  const [loading, setLoading] = useState(true);
  const [spinner, setSpinner] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    getPackages().then(() => {
      setLoading(false);
    });
  }, []);

  const onBuy = async (packageId) => {
    if (auth.token) {
      try {
        setSpinner(true);
        const headers = config.bearerHeaders(auth.token);
        const res = await config.postApi(
          SUBSCRIPTIONS_ENDPOINT,
          headers,
          JSON.stringify({ package_id: packageId })
        );
        if (res) {
          updateProfile(res);
        }
        setSpinner(false);
      } catch (e) {
        setSpinner(false);
        console.log(e);
      }
    } else {
      navigate("/login");
    }
  };

  return (
    <>
      {/* Header Start Here */}
      <Header />
      {/* Header End Here */}

      {/* InnerBanner Sec Start Here */}
      <InnerBanner PageName="Price" BannerImage={InnerBannerImage} />
      {/* InnerBanner Sec End Here */}

      {/* Packages Sec Start Here */}
      <section className="packages_sec">
        <div className="container">
          <div className="heading-wrapper wow animate__animated animate__fadeInDown">
            <h2 className="title">Choose your plan</h2>
            <p>
              You will be charged for the plan after the admin approves your
              vendor account
            </p>
          </div>
          <div className="row">
            {packages.map((singlePackage, index) => (
              <div className="col-md-6" key={`package_${singlePackage.id}`}>
                <Package
                  animationDelay={index}
                  id={singlePackage.id}
                  name={singlePackage.name}
                  inclusions={singlePackage.items}
                  price={singlePackage.price}
                  onBuy={() => onBuy(singlePackage.id)}
                  currentSubscription={
                    singlePackage.id ==
                    auth?.user?.active_subscription?.package_id
                  }
                />
              </div>
            ))}
          </div>
        </div>
      </section>
      {/* Packages Sec End Here */}

      {/* Newsletter Sec Start Here */}
      <Newsletter />
      {/* Newsletter Sec End Here */}

      {/* Footer Start Here */}
      <Footer />
      {/* Footer End Here */}

      {spinner ? <OverlaySpinner /> : null}
    </>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  packages: state.packages,
});

const mapDispatchToProps = (dispatch) => ({
  getPackages: async () => dispatch(await GetPackagesAction()),
  updateProfile: (user) => dispatch({ type: UPDATE_PROFILE, payload: user }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Packages);
