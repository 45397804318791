import config from "../../config";
import { constants } from "../../constant/Index";
import { ADDRESSES_ENDPOINT } from "../../endpoints";
import { GET_ADDRESSES, ADD_ADDRESS } from "./types";

export const GetAddressesAction = () => async (dispatch, getState) => {
  const headers = config.bearerHeaders(getState().auth.token);
  const res = await config.getApi(ADDRESSES_ENDPOINT, headers);
  if (res) {
    dispatch({ type: GET_ADDRESSES, payload: res });
  }
};

export const AddAddressAction =
  ({
    line_1,
    line_2,
    city,
    state,
    zip,
    country,
    type = constants.ADDRESS_TYPE_BILIING,
    label = "",
    is_default = false,
  }) =>
  async (dispatch, getState) => {
    const headers = config.bearerHeaders(getState().auth.token);
    const data = JSON.stringify({
      line_1,
      line_2,
      city,
      state,
      zip,
      country,
      type,
      label,
      is_default,
    });
    const res = await config.postApi(ADDRESSES_ENDPOINT, headers, data);
    if (res) {
      dispatch({ type: ADD_ADDRESS, payload: res });
    }
  };

export const UpdateAddressAction =
  (id, address) => async (dispatch, getState) => {
    const headers = config.bearerHeaders(getState().auth.token);
    const data = JSON.stringify(address);
    const res = await config.putApi(
      ADDRESSES_ENDPOINT + "/" + id,
      headers,
      data
    );
    if (res) {
    }
  };

export const DeleteAddressAction = (id) => async (dispatch, getState) => {
  const headers = config.bearerHeaders(getState().auth.token);
  const res = await config.deleteApi(ADDRESSES_ENDPOINT + "/" + id, headers);
  if (res) {
  }
};
