import React from "react";
import "../assets/css/overlay-spinner.css";
import config from "../config";
import MySpinner from "./MySpinner";

const OverlaySpinner = () => {
  return (
    <div className="spinner_container">
      <MySpinner color={config.primaryColor} />
    </div>
  );
};

export default OverlaySpinner;
