import React from "react";

const Faqs = () => {
  const faqsData = [
    {
      id: 1,
      question: "HOW DO I MANAGE LAUNDRY IN A SCABIES OUTBREAK?",
      answer:
        "Laundry: All clothing items, soft furnishings and linen used by the affected person 72 hours prior to treatment are to be washed in a hot water cycle or hot tumble dried to kill the mites. Any items that are unable to be laundered or hot-tumble-dried should be sealed in a plastic bag for 72 hours.",
    },
    {
      id: 2,
      question:
        "HOW LONG DO I NEED TO STAY AWAY FROM WORK FOLLOWING STOMACH BUG?",
      answer:
        "It’s important to stay home, away from work, school or childcare, until 48 hours after the last episode of vomiting or diarrhoea.",
    },
    {
      id: 3,
      question: "WHAT IS THE CORRECT CLEANING IN AN OUTBREAK?",
      answer:
        "During an outbreak, the cleaning frequency will need to be increased, and the efficacy of cleaning will need to be monitored on an ongoing basis. Only approved chemicals should be used, in accordance with facility cleaning protocols. Neutral detergent and hospital-grade disinfectant should be readily available. Sodium hypochlorite may also need to be used but must be utilised according to guidelines. Detergent and disinfectant wipes must also be made available Isolation rooms should be cleaned with identifiable colour-coded cleaning equipment for higher-risk areas.",
    },
    {
      id: 4,
      question: "WHAT ARE THE DIRECTIONS FOR AN OUTBREAK?",
      answer:
        "Follow your outbreak management plan. Each facility should have an outbreak management plan that clearly outlines what to do and who to contact if an outbreak is identified. As each facility may be unique due to variations in organisational structure and environment, it is important that each facility develops its own outbreak management plan.",
    },
    {
      id: 5,
      question: "WHO DO I CALL IF I HAVE AN OUTBREAK?",
      answer:
        "In the first instance, inform the charge nurse or equivalent of the outbreak and follow the outbreak management plan. Outbreak management control measures are to be implemented in consultation with the outbreak coordinator/management team, infection prevention personnel and notification advice from local public health authorities.",
    },
    {
      id: 6,
      question: "CAN YOU HELP US PREPARE FOR AN AUDIT?",
      answer:
        "Yes, we have a wide variety of tools and resources to assist and an IP expert for advice and consultation.",
    },
    {
      id: 7,
      question: "CAN YOU REVIEW OUR POLICIES AND PROCEDURES?",
      answer:
        "Yes, we can assist in reviewing your current IPC policies and procedures.",
    },
    {
      id: 8,
      question: "DO YOU HAVE A COURSE FOR AN IP LEAD?",
      answer:
        "Yes, we have a 5 module course with a certificate of attendance that you are able to complete in your own time. As part of the course, you can access educational resources to educate the team.",
    },
  ];
  return (
    <>
      <div className="accordion" id="accordionExample">
        {faqsData?.map((data, index) => (
          <div className="card" key={index}>
            <div className="card-header" id={"heading" + data?.id}>
              <h2 className="mb-0">
                <button
                  className="btn btn-link btn-block text-left"
                  type="button"
                  data-toggle="collapse"
                  data-target={"#collapse" + data?.id}
                  aria-expanded={data?.id <= 1 ? "true" : "false"}
                  aria-controls={"collapse" + data?.id}
                >
                  {data?.question}
                  <i className="fa fa-plus" aria-hidden="true"></i>
                </button>
              </h2>
            </div>

            <div
              id={"collapse" + data?.id}
              className={data?.id <= 1 ? "collapse show" : "collapse"}
              aria-labelledby={"heading" + data?.id}
              data-parent="#accordionExample"
            >
              <div className="card-body">{data?.answer}</div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default Faqs;
