import React from 'react'
import { Link } from 'react-router-dom'
import { MacScreen } from '../../constant/Index'

const Ipc = () => {
    return (
        <>
            {/* IPC Sec Start Here */}
            <section className="ipc_sec">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-7">
                            <div className="content-wrapper">
                                <h2 className="title wow animate__animated animate__lightSpeedInLeft">
                                    IPC is critical and time consuming, we’ve got products to help
                                </h2>
                                <ul className="points">
                                    <li className='wow animate__animated animate__fadeIn'>Quality Standards are always changing</li>
                                    <li className='wow animate__animated animate__fadeIn animate__delay-1s'>Insufficient IPC can cause outbreaks and loss of life</li>
                                    <li className='wow animate__animated animate__fadeIn animate__delay-2s'>Surge workforces require ongoing training</li>
                                    <li className='wow animate__animated animate__fadeIn animate__delay-3s'>Time is your most valuable resource</li>
                                </ul>
                                <h4 className="sub-title wow animate__animated animate__fadeInUp animate__delay-4s">Bug Control can help you manage Infection Prevention
                                    and Control with ease.</h4>
                                <div className="button-group wow animate__animated animate__fadeInUp animate__delay-4s">
                                    <Link className='primary-btn' to="tel:0800849211">Call now</Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-5">
                            <div className="img-box wow animate__animated animate__zoomIn">
                                <figure><img src={MacScreen} alt="mac-screen" className="img-fluid" /></figure>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* IPC Sec End Here */}
        </>
    )
}

export default Ipc