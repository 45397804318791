import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import $, { data } from "jquery";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import { Product1 } from "../../constant/Index";
import "../../assets/css/product-cart.css";
import Newsletter from "../../components/Newsletter/Newsletter";
import { connect } from "react-redux";
import {
  CLEAR_CART,
  REMOVE_FROM_CART,
  UPDATE_CART,
} from "../../redux/actions/types";
import { Link } from "react-router-dom";
import config from "../../config";

const Cart = ({ cart, updateCart, removeFromCart, clearCart }) => {
  const [subtotal, setSubtotal] = useState(0);
  const [shipping, setShipping] = useState(0);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    let localSubtotal = 0;
    cart.map((cartItem) => {
      localSubtotal += cartItem.quantity * cartItem.product.price;
    });
    setSubtotal(localSubtotal);
    setTotal(localSubtotal + shipping);
  }, [cart]);

  return (
    <>
      {/* Header Start Here */}
      <Header />
      {/* Header End Here */}

      {/* Product Detail Start Here */}
      <section className="wishlist-sec">
        <div className="container">
          <div className="row">
            <div className="col-lg-1"></div>
            <div className="col-lg-10">
              <div className="heading-wrapper">
                <h4>View Cart</h4>
              </div>
              <div className="wishlist-wrapper">
                <div className="table-responsive">
                  <table className="table">
                    <thead>
                      <tr>
                        <th className="pro-img">Image</th>
                        <th className="pro-name">PRODUCT NAME</th>
                        <th className="Avalablity-name">QUANTITY</th>
                        <th className="Action-name">SUB TOTAL</th>
                        <th className="close"></th>
                      </tr>
                    </thead>
                    <tbody>
                      {cart.map((data, index) => {
                        return (
                          <tr key={`cart_${data.product_id}`}>
                            <td className="product-img">
                              <figure>
                                <img
                                  src={config.returnImage(data.product?.cover)}
                                  alt=""
                                />
                              </figure>
                            </td>
                            <td className="product-name">
                              <div className="product-wrapper">
                                <h6>{data?.product?.name}</h6>
                              </div>
                            </td>
                            <td className="stock-name">
                              <div className="counter-wrapper">
                                <div className="counter-field-wrapper">
                                  <input
                                    type="number"
                                    value={data.quantity}
                                    onChange={(e) =>
                                      updateCart(
                                        data.product_id,
                                        e.target.value
                                      )
                                    }
                                  />
                                </div>
                                <div className="counter-btn-wrapper">
                                  <button
                                    onClick={() =>
                                      updateCart(
                                        data.product_id,
                                        data.quantity + 1
                                      )
                                    }
                                    className="plus"
                                  >
                                    <i
                                      className="fa fa-angle-up"
                                      aria-hidden="true"
                                    ></i>
                                  </button>
                                  <button
                                    onClick={() =>
                                      updateCart(
                                        data.product_id,
                                        data.quantity - 1
                                      )
                                    }
                                    className="minus"
                                  >
                                    <i
                                      className="fa fa-angle-down"
                                      aria-hidden="true"
                                    ></i>
                                  </button>
                                </div>
                              </div>
                            </td>
                            <td className="product-name">
                              <h6>${data.product.price * data.quantity} </h6>
                            </td>
                            <td className="close-col">
                              <button
                                type="button"
                                onClick={() => removeFromCart(data.product_id)}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16.5"
                                  height="21.214"
                                  viewBox="0 0 16.5 21.214"
                                >
                                  <g
                                    id="cancel"
                                    transform="translate(2.25 6.698)"
                                  >
                                    <path
                                      id="Icon_material-delete-forever"
                                      data-name="Icon material-delete-forever"
                                      d="M8.679,23.357a2.364,2.364,0,0,0,2.357,2.357h9.429a2.364,2.364,0,0,0,2.357-2.357V9.214H8.679Zm2.9-8.391L13.24,13.3l2.51,2.5,2.5-2.5,1.662,1.662-2.5,2.5,2.5,2.5-1.662,1.662-2.5-2.5-2.5,2.5L11.59,19.963l2.5-2.5Zm8.3-9.287L18.7,4.5H12.8L11.625,5.679H7.5V8.036H24V5.679Z"
                                      transform="translate(-9.75 -11.198)"
                                      fill="#f6a309"
                                    />
                                  </g>
                                </svg>
                              </button>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
                <div className="discount-row-wrapper">
                  <div className="row">
                    <div className="col-lg-6"></div>
                    <div className="col-lg-2"></div>
                    <div className="col-lg-4">
                      <div className="discount-wrapper">
                        <div className="discount-list-wrapper">
                          <ul>
                            <li>
                              <span className="property">Subtotal</span>
                              <span className="value">
                                $ {subtotal.toFixed(2)}
                              </span>
                            </li>
                            <li>
                              <span className="property">
                                Estimated Shipping Cost
                              </span>
                              <span className="value">
                                {shipping == 0
                                  ? "Free Shipping"
                                  : "$ " + shipping.toFixed(2)}
                              </span>
                            </li>
                          </ul>
                        </div>
                        <div className="total-cost-wrapper">
                          <ul>
                            <li>
                              <span className="property"> TOTAL COST </span>
                              <span className="value">
                                $ {total.toFixed(2)}
                              </span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6">
                  <div className="btn-wrapper fst-btn">
                    <Link to="/shop" className="btn text-white">
                      Continue Shopping
                    </Link>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="btn-wrapper text-right">
                    <Link to="/checkout" className="btn">
                      Proceed to Checkout
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-1"></div>
          </div>
        </div>
      </section>
      {/* Product Detail End Here */}

      {/* Newsletter Sec Start Here */}
      <Newsletter />
      {/* Newsletter Sec End Here */}

      {/* Footer Start Here */}
      <Footer />
      {/* Footer End Here */}
    </>
  );
};

const mapStateToProps = (state) => ({
  cart: state.cart,
});

const mapDispatchToProps = (dispatch) => ({
  removeFromCart: (product_id) =>
    dispatch({ type: REMOVE_FROM_CART, payload: product_id }),
  updateCart: (product_id, quantity) =>
    dispatch({ type: UPDATE_CART, payload: { product_id, quantity } }),
  clearCart: () => dispatch({ type: CLEAR_CART }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Cart);
