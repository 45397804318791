import React from "react";

const AddressSelector = ({ address, selected = false, onChange = null }) => {
  return (
    <label className="container">
      <input
        type="radio"
        checked={selected}
        name="Addressradio"
        onChange={(e) => {
          if (e.target.checked) {
            onChange(address.id);
          }
        }}
      />
      <span className="checkmark">
        <div className="select-address">
          <div className="selection">
            <i className="fa fa-check" aria-hidden="true"></i>
          </div>
          <div className="radio-btn">
            <span></span>
          </div>
          <div className="address-detail">
            <div className="top">
              <h5>
                <span className="property">Country:</span>
                <span className="value">{address.country}</span>
              </h5>
              <h5>
                <span className="property">State:</span>
                <span className="value">{address.state}</span>
              </h5>
            </div>
            <h5>
              <span className="property">Address:</span>
              <span className="value">
                {address.line_1 + (address.line_2 ? " " + address.line_2 : "")}
              </span>
            </h5>
            {/* <h5 className="last">
                              <span className="property">Phone:</span>
                              <span className="value">+44-123-456-789</span>
                            </h5> */}
            <div className="button-group">
              {address.is_default ? (
                <button className="btn default disabled">Default</button>
              ) : null}
            </div>
          </div>
        </div>
      </span>
    </label>
  );
};

export default AddressSelector;
