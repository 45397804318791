import { ADD_PAYMENT_METHOD, GET_PAYMENT_METHODS } from "./types";
import config from "../../config";
import { PAYMENT_METHODS_ENDPOINT } from "../../endpoints";

export const GetPaymentMethodsAction = () => async (dispatch, getState) => {
  const headers = config.bearerHeaders(getState().auth.token);
  const res = await config.getApi(PAYMENT_METHODS_ENDPOINT, headers);
  if (res) {
    dispatch({ type: GET_PAYMENT_METHODS, payload: res });
  }
};

export const AddPaymentMethodAction =
  (payment_source) => async (dispatch, getState) => {
    const headers = config.bearerHeaders(getState().auth.token);
    const data = JSON.stringify({ payment_source });
    const res = await config.postApi(PAYMENT_METHODS_ENDPOINT, headers, data);
    if (res) {
      dispatch({ type: ADD_PAYMENT_METHOD, payload: res });
    }
  };

export const UpdatePaymentMethodAction =
  (id, default_source) => async (dispatch, getState) => {
    const headers = config.bearerHeaders(getState().auth.token);
    const data = JSON.stringify({ default_source });
    const res = await config.putApi(
      PAYMENT_METHODS_ENDPOINT + "/" + id,
      headers,
      data
    );
    if (res) {
    }
  };
