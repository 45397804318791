import React, { useEffect, useState } from "react";
import InnerBanner from "../../components/InnerBanner/InnerBanner";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import { InnerBannerImage2 } from "../../constant/Index";
import Product from "../../components/Product/Product";
import { Link, useSearchParams } from "react-router-dom";
import Newsletter from "../../components/Newsletter/Newsletter";
import config from "../../config";
import { PRODUCTS_ENDPOINT } from "../../endpoints";
import MySpinner from "../../components/MySpinner";

const Shop = () => {
  const [searchParams] = useSearchParams();

  const [products, setProducts] = useState({});

  const [loading, setLoading] = useState(true);

  const [currentPage, setCurrentPage] = useState(searchParams.get("page") || 1);

  const getProducts = async () => {
    try {
      setLoading(true);
      const res = await config.getApi(
        PRODUCTS_ENDPOINT + "?page=" + currentPage,
        config.defaultHeaders
      );
      setProducts(res);
      setLoading(false);
      console.log(res);
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  };

  const getMoreProducts = async (link) => {
    try {
      setCurrentPage(link.split("=")[1]);
      setLoading(true);
      const res = await config.getApi(link, config.defaultHeaders);
      setProducts(res);
      setLoading(false);
      console.log(res);
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  };

  useEffect(() => {
    setCurrentPage(searchParams.get("page") || 1);

    getProducts().then();
  }, []);

  return (
    <>
      {/* Header Start Here */}
      <Header />
      {/* Header End Here */}

      {/* InnerBanner Start Here */}
      <InnerBanner PageName="Shop" BannerImage={InnerBannerImage2} />
      {/* InnerBanner End Here */}

      {/* Products Start Here */}
      <section className="product_sec">
        <div className="container">
          {loading ? (
            <MySpinner />
          ) : (
            <>
              <div className="row">
                <Product ProductData={products.data} />
              </div>
              <ul className="pagination">
                {products.links.map((paginationLink, key) => {
                  //   if (paginationLink.url) {
                  return (
                    <li key={`pagination_link_${key}`}>
                      <Link
                        //   to={`/shop?page=${paginationLink.label}`}
                        className={
                          currentPage == paginationLink.label ? "active" : ""
                        }
                        onClick={
                          paginationLink.url
                            ? () => getMoreProducts(paginationLink.url)
                            : null
                        }
                        dangerouslySetInnerHTML={{
                          __html: paginationLink.label.includes("Next")
                            ? ">>"
                            : paginationLink.label.includes("Prev")
                            ? "<<"
                            : paginationLink.label,
                        }}
                      ></Link>
                    </li>
                  );
                  //   }
                })}
                {/* <li>
                  <Link to="#" className="active">
                    1
                  </Link>
                </li>
                <li>
                  <Link to="#">2</Link>
                </li>
                <li>
                  <Link to="#">
                    <i
                      className="fa fa-long-arrow-right"
                      aria-hidden="true"
                    ></i>
                  </Link>
                </li> */}
              </ul>
            </>
          )}
        </div>
      </section>
      {/* Products End Here */}

      {/* Newsletter Sec Start Here */}
      <Newsletter />
      {/* Newsletter Sec End Here */}

      {/* Footer Start Here */}
      <Footer />
      {/* Footer Start Here */}
    </>
  );
};

export default Shop;
