import React from "react";
import { FormFeedback, FormGroup, Input, Label } from "reactstrap";

const MyInputFormGroup = ({
  value,
  onChange = null,
  placeholder = "",
  type = "text",
  errors = [],
  label = "",
  showLabel = false,
  inputId = "",
  accept = "",
  textarea = false,
  disabled = false,
  options = [],
  inputClassNames = [],
}) => {
  let selectOptions = (
    <>
      {options.map((singleOption) => (
        <option
          key={`option_${inputId}_${singleOption.value}`}
          value={singleOption.value}
        >
          {singleOption.label}
        </option>
      ))}
    </>
  );

  return (
    <FormGroup className="form-group">
      {showLabel && <Label htmlFor={inputId}>{label}</Label>}
      <Input
        className={inputClassNames.join(" ")}
        disabled={disabled}
        type={textarea ? "textarea" : type}
        id={inputId}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        invalid={errors.length > 0}
        accept={accept}
      >
        {type === "select" ? selectOptions : null}
      </Input>
      <FormFeedback>{errors.map((err) => err)}</FormFeedback>
    </FormGroup>
  );
};

export default MyInputFormGroup;
