import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Blog from "../../components/Blog/Blog";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import InnerBanner from "../../components/InnerBanner/InnerBanner";
import MySpinner from "../../components/MySpinner";
import Newsletter from "../../components/Newsletter/Newsletter";
import config from "../../config";
import { NewsBanner } from "../../constant/Index";
import { BLOGS_ENDPOINT } from "../../endpoints";

const News = () => {
  const [loading, setLoading] = useState(true);

  const [blogs, setBlogs] = useState([]);

  useEffect(() => {
    config.getApi(BLOGS_ENDPOINT, config.defaultHeaders).then((blogs) => {
      setBlogs(blogs);
      setLoading(false);
    });
  }, []);

  return (
    <>
      {/* Header Start Here */}
      <Header />
      {/* Header End Here */}

      {/* InnerBanner Sec Start Here */}
      <InnerBanner PageName="News & Updates" BannerImage={NewsBanner} />
      {/* InnerBanner Sec End Here */}

      {loading ? (
        <div className="d-flex justify-content-center align-items-center p-4">
          <MySpinner color={config.primaryColor} />
        </div>
      ) : (
        <>
          {/* News Sec Start Here */}
          <section className="news_sec">
            <div className="container">
              <div className="heading_wrapper">
                <h2>
                  OUR BUG CONTROL BLOG IS PACKED WITH USEFUL ARTICLES ON
                  INFECTION CONTROL CHALLENGES IN AGED CARE
                </h2>
              </div>
              <div className="row">
                <Blog blogData={blogs} />
              </div>
              {/* <ul className="pagination justify-content-center">
                <li>
                  <Link to="#" className="active">
                    1
                  </Link>
                </li>
                <li>
                  <Link to="#">2</Link>
                </li>
                <li>
                  <Link to="#">
                    <i
                      className="fa fa-long-arrow-right"
                      aria-hidden="true"
                    ></i>
                  </Link>
                </li>
              </ul> */}
            </div>
          </section>
          {/* News Sec End Here */}
        </>
      )}

      {/* Newsletter Sec Start Here */}
      <Newsletter />
      {/* Newsletter Sec End Here */}

      {/* Footer Start Here */}
      <Footer />
      {/* Footer End Here */}
    </>
  );
};

export default News;
