export const LOGIN_ENDPOINT = "/login";
export const REGISTER_ENDPOINT = "/register";
export const CATEGORIES_ENDPOINT = "/categories";
export const PRODUCTS_ENDPOINT = "/products";
export const ADDRESSES_ENDPOINT = "/addresses";
export const PAYMENT_METHODS_ENDPOINT = "/payment-methods";
export const PACKAGES_ENDPOINT = "/packages";
export const SUBSCRIPTIONS_ENDPOINT = "/subscriptions";
export const BLOGS_ENDPOINT = "/blogs";

export const CONTACT_ENDPOINT = "/contact/contact";
export const ORDERS_ENDPOINT = "/orders";

export const ME_ENDPOINT = "/me";
export const UPDATE_PROFILE_ENDPOINT = "/update-profile";
export const CHANGE_PASSWORD_ENDPOINT = "/change-password";

export const SEND_RESET_EMAIL_ENDPOINT = "/password/email";
export const RESET_PASSWORD_ENDPOINT = "/password/reset";
