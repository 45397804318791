import React from "react";

const MySpinner = ({ color = undefined }) => {
  return (
    <div className="spinner-border" style={{ color: color }} role="status">
      <span className="sr-only">Loading...</span>
    </div>
  );
};

export default MySpinner;
