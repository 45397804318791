import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import { ProductIcon1, ProductIcon2, ProductIcon3 } from '../../constant/Index'

const RangeProducts = () => {
    const location = useLocation();
    const currentlocation = location.pathname;
    const productData = [
        {
            id: 1,
            icon: ProductIcon1,
            title: "IPC Knowledge Base",
            description: "Search over 200 Articles + dozens of features with ease",
            subscription: "Subscribe from $200 pcm",
        },
        {
            id: 1,
            icon: ProductIcon2,
            title: "Policies & Procedures",
            description: "Meet the standards with 16 automatically updated Policies & Procedures",
            subscription: "Included in Subscription",
        },
        {
            id: 1,
            icon: ProductIcon3,
            title: "IP Lead Training",
            description: "Interactive online course developed by Aged Care IP Lead experts",
            subscription: "$300 or Included in Pro Subscription",
        }
    ]
    return (
        <>
            {productData?.map((data, index) => (
                <div className={currentlocation == "/" ? "col-lg-4" : ""} key={index}>
                    <div className="software-product-box wow animate__animated animate__flipInY">
                        <div className="icon-box">
                            <figure><img src={data?.icon} alt="icon" className="img-fluid" /></figure>
                        </div>
                        <div className="content">
                            <h3 className="title">{data?.title}</h3>
                            <p>
                                {data?.description}
                            </p>
                            <p className="colored">{data?.subscription}</p>
                        </div>
                        <div className="button-group">
                            <Link to="/packages" className='primary-btn'>Learn More</Link>
                        </div>
                    </div>
                </div>
            ))}
        </>
    )
}

export default RangeProducts