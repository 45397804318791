import React from "react";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import InnerBanner from "../../components/InnerBanner/InnerBanner";
import Product from "../../components/Product/Product";
import ShopContent from "../../components/ShopContent/ShopContent";
import {
  BannerImage3,
  EducationalResourceImg,
  Product1,
  Product2,
  Product3,
  Product4,
  Product5,
  Product6,
  Product7,
  Product8,
  Product9,
} from "../../constant/Index";

const AmsCompendiums = () => {
  const ProductDatas = [
    {
      id: 1,
      image: Product1,
      name: "AMS Compendium Bundle",
      price: "2079",
    },
    {
      id: 2,
      image: Product2,
      name: "AMS Compendium for C. Diff",
      price: "459",
    },
    {
      id: 3,
      image: Product3,
      name: "AMS Compendium for Cellulitis",
      price: "2079",
    },
    {
      id: 4,
      image: Product4,
      name: "AMS Compendium Bundle",
      price: "2079",
    },
    {
      id: 5,
      image: Product5,
      name: "AMS Compendium Bundle",
      price: "2079",
    },
    {
      id: 6,
      image: Product6,
      name: "AMS Compendium Bundle",
      price: "2079",
    },
    {
      id: 7,
      image: Product7,
      name: "AMS Compendium Bundle",
      price: "2079",
    },
    {
      id: 8,
      image: Product8,
      name: "AMS Compendium Bundle",
      price: "2079",
    },
    {
      id: 9,
      image: Product9,
      name: "AMS Compendium Bundle",
      price: "2079",
    },
  ];
  return (
    <>
      {/* Header Start Here */}
      <Header />
      {/* Header End Here */}

      {/* InnerBanner Start Here */}
      <InnerBanner BannerImage={BannerImage3} />
      {/* InnerBanner End Here */}

      {/* Content Sec Start Here */}
      <ShopContent
        title="AMS Compendiums"
        description="We proudly present our Antimicrobial Stewardship Compendiums. Improve the well-being of your residents by reducing inappropriate antibiotic use as part of your facility’s infection prevention culture. Our team of RN's and Senior Consultants have developed these resources to help you improve your infection prevention practices. By increasing the knowledge and skills of your team, together we can improve the early detection and accurate treatment of infections."
        image={EducationalResourceImg}
      />
      {/* Content Sec End Here */}

      {/* Products Start Here */}
      <section className="product_sec">
        <div className="container">
          <div className="row">
            <Product ProductData={ProductDatas} />
          </div>
          {/* <ul className="pagination">
                        <li><Link to="#" className='active'>1</Link></li>
                        <li><Link to="#">2</Link></li>
                        <li><Link to="#"><i className="fa fa-long-arrow-right" aria-hidden="true"></i></Link></li>
                    </ul> */}
        </div>
      </section>
      {/* Products End Here */}

      {/* Footer Start Here */}
      <Footer />
      {/* Footer End Here */}
    </>
  );
};

export default AmsCompendiums;
