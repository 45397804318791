import React from 'react'
import Slider from 'react-slick';
import { Brand1, Brand10, Brand11, Brand12, Brand13, Brand14, Brand15, Brand2, Brand3, Brand4, Brand5, Brand6, Brand7, Brand8, Brand9 } from '../../constant/Index'
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import { A11y, Navigation, Pagination, Scrollbar } from 'swiper';
import 'swiper/css/pagination';

const Brands = () => {
    const brandLogos = [
        {
            id: 1,
            logo: Brand1,
        },
        {
            id: 2,
            logo: Brand2,
        },
        {
            id: 3,
            logo: Brand3,
        },
        {
            id: 4,
            logo: Brand4,
        },
        {
            id: 6,
            logo: Brand6,
        },
        {
            id: 7,
            logo: Brand7,
        },
        {
            id: 8,
            logo: Brand8,
        },
        {
            id: 9,
            logo: Brand9,
        },
        {
            id: 10,
            logo: Brand10,
        },
        {
            id: 11,
            logo: Brand11,
        },
        {
            id: 12,
            logo: Brand12,
        },
        {
            id: 13,
            logo: Brand13,
        },
        {
            id: 14,
            logo: Brand14,
        },

        {
            id: 15,
            logo: Brand15,
        },
    ]
    return (
        <>
            {/* Brands Sec Start Here */}
            <section className="brands_sec">
                <div className="container">
                    <div className="heading-wrapper wow animate__animated animate__fadeInDown">
                        <h2 className="title">We’ve helped thousands of facilities manage Infection Prevention and Control</h2>
                    </div>
                    <div className="text-wrapper wow animate__animated animate__fadeInLeft animate__delay-1s">
                        <h3 className="title">Trusted by</h3>
                    </div>
                    <div className="brands-slider">
                        <Swiper
                            modules={[Navigation, Pagination, Scrollbar, A11y]}
                            navigation={{
                                prevEl: 'prev',
                                nextEl: 'next',
                            }}
                            breakpoints={{
                                // when window width is >= 320px
                                320: {
                                    slidesPerView: 2,
                                    spaceBetween: 20
                                },
                                // when window width is >= 480px
                                480: {
                                    slidesPerView: 3,
                                    spaceBetween: 30
                                },
                                // when window width is >= 640px
                                640: {
                                    slidesPerView: 4,
                                    spaceBetween: 40
                                },
                                992: {
                                    slidesPerView: 5,
                                    spaceBetween: 40
                                }
                            }}
                            spaceBetween={50}
                            slidesPerView={6}
                            pagination={{ clickable: true }}
                        >
                            {brandLogos?.map((data, index) => (
                                <SwiperSlide key={index}>
                                    <figure><img src={data?.logo} alt="brand-icon" className="img-fluid" /></figure>
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </div>
                </div>
            </section>
            {/* Brands Sec End Here */}
        </>
    )
}

export default Brands