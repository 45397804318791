import React from 'react'

const InnerBanner = (props) => {
    const PageName = props.PageName;
    const BannerImage = props.BannerImage;
    return (
        <>
            {/* InnerBanner Start Here */}
            <section className="innerbanner_sec wow animate__animated animate__zoomIn">
                <div className="img-box">
                    <figure><img src={BannerImage} alt="banner-img" className="img-fluid" /></figure>
                </div>
                <div className="container">
                    <div className="title">
                        <h1>{PageName}</h1>
                    </div>
                </div>
            </section>
            {/* InnerBanner End Here */}
        </>
    )
}

export default InnerBanner
