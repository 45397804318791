import React from "react";

const MyInput = ({
  value,
  onChange = null,
  placeholder = "",
  type = "text",
  errors = [],
  label = "",
  showLabel = false,
  inputId = "",
  accept = "",
  textarea = false,
  disabled = false,
}) => {
  if (!textarea) {
    return (
      <div className="form_row mt-3">
        <div className="the_input w-100">
          <input
            className={
              "form-control " + (errors.length > 0 ? "is-invalid" : "")
            }
            type={type}
            id={inputId}
            placeholder={placeholder}
            disabled={disabled}
            value={value}
            onChange={onChange}
            accept={accept}
          />
          <div className="invalid-feedback">{errors.map((err) => err)}</div>
        </div>
      </div>
    );
    // return (
    //   <div className="form-group">
    //     {showLabel && <label htmlFor={inputId}>{label}</label>}
    //     <input
    //       disabled={disabled}
    //       id={inputId}
    //       type={type}
    //       placeholder={placeholder}
    //       className={"form-control " + (errors.length > 0 ? "is-invalid" : "")}
    //       value={value}
    //       onChange={onChange}
    //       accept={accept}
    //     />
    //     <div className="invalid-feedback">{errors.map((err) => err)}</div>
    //   </div>
    // );
  }
  //   return (
  //     <FormGroup>
  //       {showLabel && <Label htmlFor={inputId}>{label}</Label>}
  //       <Input
  //         disabled={disabled}
  //         type="textarea"
  //         id={inputId}
  //         value={value}
  //         onChange={onChange}
  //         placeholder={placeholder}
  //         invalid={errors.length > 0}
  //       />
  //       <FormFeedback>{errors.map((err) => err)}</FormFeedback>
  //     </FormGroup>
  //   );
  return (
    <div className="form_row mt-3">
      <div className="the_input w-100">
        <textarea
          id={inputId}
          placeholder={placeholder}
          disabled={disabled}
          value={value}
          onChange={onChange}
          accept={accept}
        />
      </div>
    </div>
  );
};

export default MyInput;
