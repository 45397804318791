import { GET_ORDERS, CHECKOUT, CLEAR_ORDERS } from "../actions/types";

const orders = (state = [], action) => {
  switch (action.type) {
    case GET_ORDERS:
      return action.payload;
    case CHECKOUT:
      return [action.payload, ...state];
    case CLEAR_ORDERS:
      return [];
    default:
      return state;
  }
};

export default orders;
