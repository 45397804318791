import React from 'react'

const Newsletter = () => {
    return (
        <>
            {/* Newseltter Sec Start Here */}
            <section className="newsletter">
                <div className="container">
                    <div className="heading-wrapper wow animate__animated animate__fadeInDown">
                        <h2>Subscribe to our newsletter</h2>
                        <p>
                            For helpful leads, new tools and more delivered right to your inbox.
                        </p>
                    </div>
                    <div className="newsletter-box wowa animate__animated animate__fadeInUp">
                        <form action="">
                            <div className="form-group">
                                <input type="email" placeholder='Enter your email' className="form-control" />
                            </div>
                            <div className="button-group">
                                <button className="primary-btn">Submit</button>
                            </div>
                        </form>
                    </div>
                </div>
            </section>
            {/* Newseltter Sec End Here */}
        </>
    )
}

export default Newsletter