import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import config from "../../config";
import { ORDERS_ENDPOINT } from "../../endpoints";

const ThankYou = ({ authToken }) => {
  const [order, setOrder] = useState({});

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    getOrder().then();
  }, []);

  const getOrder = async () => {
    try {
      const res = await config.getApi(
        ORDERS_ENDPOINT + "/" + searchParams.get("order_id"),
        config.bearerHeaders(authToken)
      );
      if (res) {
        setOrder(res);
      } else {
        navigate("/404");
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      {/* Header Start Here */}
      <Header />
      {/* Header End Here */}

      {/* Thank You Sec Start Here */}
      <section className="thankyou-sec">
        <div className="container">
          <div className="head">
            <h1>Thank You for Shopping!!</h1>
            <h4>Your Order is confirmed</h4>
          </div>
          <div className="order-nmber">
            <h4>OrderNo#{order?.id}</h4>
          </div>
          <div className="row">
            <div className="col-lg-3 col-md-2"></div>
            <div className="col-lg-6 col-md-8">
              <div className="order-detail">
                <div className="shipment-address">
                  <div className="title">
                    <h5>Shipment Address</h5>
                  </div>
                  <ul className="address">
                    <li className="country">
                      <span className="property">Country:</span>
                      <span className="value">{order?.address?.country}</span>
                    </li>
                    <li className="address">
                      <span className="property">Address:</span>
                      <span className="value">
                        {order?.address?.line_1 +
                          (order?.address?.line_2
                            ? " " + order.address.line_2
                            : "")}
                      </span>
                    </li>
                    {/* <li className="phone">
                      <span className="property">Phone:</span>
                      <span className="value">+44-123-456-789</span>
                    </li> */}
                    <li className="state">
                      <span className="property">State:</span>
                      <span className="value">{order?.address?.state}</span>
                    </li>
                  </ul>
                </div>
                <div className="payment-detail">
                  <div className="title">
                    <h5>Payment details</h5>
                  </div>
                  <ul className="payment-total">
                    <li className="item-total">
                      <span className="property">Item Total :</span>
                      <span className="value">
                        ${config.numberFormat(order?.subtotal)}
                      </span>
                    </li>
                    {/* <li className="delivery">
                      <span className="property">Shipment &amp; Delivery</span>
                      <span className="value">${config.numberFormat(order?.)}</span>
                    </li> */}
                  </ul>
                </div>
                <div className="order-total">
                  <h3 className="property">Order Total</h3>
                  <h3 className="value">
                    ${config.numberFormat(order?.total)}
                  </h3>
                </div>
              </div>
              <Link className="btn" to="/shop" type="button">
                Continue Shopping
              </Link>
            </div>
            <div className="col-lg-3 col-md-2"></div>
          </div>
        </div>
      </section>
      {/* Thank You Sec End Here */}

      {/* Footer Start Here */}
      <Footer />
      {/* Footer End Here */}
    </>
  );
};

const mapStateToProps = (state) => ({
  authToken: state.auth.token,
});

export default connect(mapStateToProps)(ThankYou);
