import moment from "moment";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import InnerBanner from "../../components/InnerBanner/InnerBanner";
import MySpinner from "../../components/MySpinner";
import Newsletter from "../../components/Newsletter/Newsletter";
import config from "../../config";
import { BlogImg1, LegalBanner } from "../../constant/Index";
import { BLOGS_ENDPOINT } from "../../endpoints";

const NewsDetail = () => {
  const [blog, setBlog] = useState({});

  const [loading, setLoading] = useState(true);

  const { id } = useParams();

  useEffect(() => {
    config
      .getApi(BLOGS_ENDPOINT + "/" + id, config.defaultHeaders)
      .then((blog) => {
        setBlog(blog);
        setLoading(false);
      });
  }, []);

  return (
    <>
      {/* Header Start Here */}
      <Header />
      {/* Header End Here */}

      {/* InnerBanner Start Here */}
      <InnerBanner PageName="News & Updates" BannerImage={LegalBanner} />
      {/* InnerBanner End Here */}

      {loading ? (
        <div className="d-flex justify-content-center align-items-center p-4">
          <MySpinner />
        </div>
      ) : (
        <>
          {/* Newsdetail Sec Start Here */}
          <section className="news-detail">
            <div className="container">
              <div className="news_detail_box">
                <ul className="breadcrumbs">
                  <li>
                    <Link to="/news">News</Link>
                  </li>
                  <li>/ Resources / {blog.title}</li>
                </ul>
                <div className="news-image">
                  <figure>
                    <img
                      src={config.returnImage(blog.cover)}
                      alt="news-detail-image"
                      className="img-fluid"
                    />
                  </figure>
                </div>
                <div className="content-wrapper">
                  <div className="heading-wrapper">
                    <h4 className="date">
                      <i className="fa fa-calendar" aria-hidden="true"></i>{" "}
                      {/* April 27, 2022 */}
                      {moment(blog.created_at).format("MMMM D, YYYY")}
                    </h4>
                    <h2 className="title">{blog.title}</h2>
                  </div>
                  <div dangerouslySetInnerHTML={{ __html: blog.content }}></div>
                  {/* <div className="passage">
                    <p>
                      As an experienced nurse and manager, I remember my
                      incognisant confidence that I was ready for this Covid
                      monster. I was still confident at the time of the dreaded
                      outbreak announcement. I was already dealing with a large
                      RSV outbreak with 10 staff off and 16 residents in
                      isolation, and to top that off I also had the virus.
                      <br />
                      <br />
                      How much worse could it be than this?
                      <br />
                      <br />
                      Well, I was just about to find out.
                    </p>
                  </div>
                  <div className="heading-wrapper">
                    <h2>Covid hits harder and faster than you think</h2>
                  </div>
                  <div className="passage">
                    <p>
                      Pandemic plans can’t articulate the resiliencies and
                      requirements needed when you lose over 50% of your
                      workforce, and it was actually much worse than that. I
                      only had 8 members of the original team and not one RN. I
                      asked more of these remaining staff than was reasonable
                      and they all exceeded expectations in work and kindness.
                      <br />
                      <br />
                      I’d like to say that this experience made me stronger but
                      in truth, it almost broke me. Before this event hit, I had
                      the idealistic view that the DHB would be my knight on a
                      white horse. I thought that they would be there to save
                      the day in this kind of situation, but I was about to be
                      disappointed.
                      <br />
                      <br />
                      A Covid outbreak is unlike any other: the stakes are
                      higher, the tension is greater, and to add to this I had
                      no staff.
                      <br />
                      <br />
                      My story does get better. We had a homecare company that
                      sent a variety of healthcare workers and professionals to
                      cover the roster. To them, I will be eternally grateful.
                      <br />
                      <br />
                      I’ve started this blog by talking about my experience, but
                      that was nothing compared to the challenges the homecare
                      staff encountered as they entered an aged care facility
                      for the first time to find us at our worst moment. We were
                      mostly unprepared to support them for the physical and
                      emotional work we asked of them.
                    </p>
                  </div>
                  <div className="heading-wrapper">
                    <h2>Doggy-paddling in the deep end</h2>
                  </div>
                  <div className="pasaage">
                    <p>
                      Initially, when given our staffing package for the day, we
                      were not given names, “6 coming this morning Cara, 4 this
                      afternoon, 3 tonight.” The relief that there would be
                      staff on the floor… phew. When you’re underwater and
                      someone throws you a rope you don’t ask questions: you
                      just grab it and hang on.
                      <br />
                      <br />
                      This was in no way enough staff to run a facility. Even
                      though we had the numbers, imagine all your staff being
                      new with no-one, no way and no time to orientate them. I
                      needed extra staff to fill PPE trolleys, move the pallets
                      of PPE delivered to the door daily, empty the bins, clean
                      nightingale tables and put linen away and provide clinical
                      governance, but we didn’t have those people. I would find
                      myself spread so thin that hours of the day would be lost
                      in a blur of cold coffee and adrenaline rushes.
                      <br />
                      <br />
                      My experience lasted just over three weeks and in that
                      time I developed a range of handwritten, reactive
                      resources for staff. I have since converted them to
                      downloadable documents, which you can find throughout this
                      blog. Primarily they were created to manage the risks to
                      staff, the facility and the resident, but I did take the
                      opportunity to say thank you on nearly all of the
                      documents to compensate in a small way for my inability to
                      do this in person.
                    </p>
                  </div>
                  <div className="heading-wrapper">
                    <h2>Finding our feet and meeting the team</h2>
                  </div>
                  <p>
                    As the days went by during the outbreak, I wanted to
                    identify who the workers were and know their profession.
                    There were a few reasons for this. As a new staff member
                    helping that day, I believed their minimum expectation would
                    be that we could identify them by name and be aware of their
                    qualifications and skill level in relation to the job we
                    were expecting them to do.
                    <br />
                    <br />
                    With my manager’s hat on, skill mix was a safety concern. We
                    became aware that speech-language therapists and medical
                    administration staff were being asked to deliver care with
                    no experience in providing hands-on personal care to the
                    elderly.
                    <br />
                    <br />
                    Secondly, I attempted (as reminded at daily meetings) to
                    cohort staff as much as possible, even isolate dementia
                    residents. This is a huge, untenable gap between gold
                    standard and reality. But the simple logistics of no-one
                    being there to meet the staff when they arrived was an
                    issue. If they were lucky, they might meet a Big Bird
                    look-a-like (that’s me) as they entered the building,
                    otherwise they were left to fend for themselves.
                  </p>
                  <div className="heading-wrapper">
                    <h2>Outbreak resources</h2>
                  </div>
                  <div className="passage">
                    <h4>Are you here to help?</h4>
                    <p>
                      My first resource was the ‘Are you here to help?’ sheet,
                      which was designed to give important orientation
                      information quickly. This resource was further developed
                      to add answers to the questions they all asked as they
                      arrived: “where am I working?” and the second question was
                      always “what is the Wi-Fi password?”.
                      <br />
                      <br />I went on to further develop this resource to add
                      where they could eat and that a meal was provided. In time
                      I added the codes to cupboards with key codes and the
                      storage etc., and most importantly who they reported to.
                      <Link to="#">
                        You can download ‘Are you here to help?’ here.
                      </Link>
                    </p>
                    <h4>Who can I call if I have a problem?</h4>
                    <p>
                      There was a day when I arrived and as I walked around the
                      facility I saw PPE trolleys empty and the staff going from
                      resident to resident wearing the same gown. AHHHH!!!
                      <br />
                      <br />
                      In the interest of staying calm and the old adage of
                      seeking to understand, the staff explained that the
                      trolleys had not been filled as all the staff were new and
                      they didn’t know who to ask. I realised that we needed a
                      resource for improved communication to help new staff out
                      and improve compliance.
                      <br />
                      <br />
                      This resource is a list of staff, identifying their role,
                      cell number and two reasons why you would want to contact
                      them. These were positioned throughout the facility with
                      excellent results. Not only did this improve compliance,
                      but now the workload appeared to be more evenly
                      distributed. Staff knew who they needed to contact and for
                      what reason, instead of just saying “I’ll ask the
                      manager.”
                      <Link to="#">
                        You can download ‘Who can I call if I have a problem?’
                        here.
                      </Link>
                    </p>
                    <h4>Easy fixes for big problems</h4>
                    <p>
                      <Link to="#">Shift planners</Link> were developed in A3
                      with simple instructions of what was expected on an hourly
                      basis, guiding overwhelmed and disorientated staff.{" "}
                      <Link to="#">One-page care plans</Link> for resident doors
                      were developed and joined later by a{" "}
                      <Link to="#">one-page ‘10 drinks a day chart’</Link> which
                      the staff ticked when a resident had a drink. This sheet
                      of paper reduced the incidence of dehydration and
                      successfully reduced UTI symptoms.
                      <br />
                      <br />
                      I learned that the essential focus in a crisis is to
                      recognise the impact uncertainty has on the people you
                      rely on to provide day-to-day care. In these times,
                      empathy and compassion for the staff and residents was my
                      focus. As a manager, my job was to help them do their
                      jobs. The resources I made and tested are now available
                      for all who would like to download them from our website.
                      <br />
                      <br />
                      There is also a{" "}
                      <Link to="#">COVID preparedness checklist</Link> for you
                      to download.
                      <br />
                      <br />
                      Don’t get me wrong, there was a lot I would do differently
                      given the chance. I admit I didn’t get it right in many
                      areas, but these resources really worked, and as the days
                      and weeks went by it got easier. The best advice is to be
                      ready. Prepare all outbreak resources ahead of time. And
                      as a thank you for reaching the end, if I can offer any
                      last pockets of wisdom:{" "}
                      <Link to="#">
                        label all doors that are not identified as a resident’s
                        room
                      </Link>
                      . This is instant orientation for all who enter the
                      building.
                      <br />
                      <br />
                      I hope you never need these resources, but if you do, I
                      hope that they help.
                      <br />
                      <br />
                      <i>
                        Cara Kelly is Bug Control New Zealand’s Senior
                        Consultant. Having managed a Covid outbreak, she is keen
                        to help facilities in New Zealand reduce infections and
                        better manage outbreaks. To this end, she has created
                        our <Link to="#">IP Lead Programme</Link> and our new{" "}
                        <Link to="#">HCA Education Programme</Link>, which you
                        can trial for free today.
                      </i>
                    </p>
                  </div> */}
                </div>
              </div>
            </div>
          </section>
          {/* Newsdetail Sec End Here */}
        </>
      )}

      {/* Newsletter Start Here */}
      <Newsletter />
      {/* Newsletter End Here */}

      {/* Footer Start Here */}
      <Footer />
      {/* Footer End Here */}
    </>
  );
};

export default NewsDetail;
