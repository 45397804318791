import { GET_PACKAGES } from "./types";
import config from "../../config";
import { PACKAGES_ENDPOINT } from "../../endpoints";

export const GetPackagesAction = () => async (dispatch, getState) => {
  const res = await config.getApi(PACKAGES_ENDPOINT, config.defaultHeaders);
  if (res) {
    dispatch({ type: GET_PACKAGES, payload: res });
  }
};
