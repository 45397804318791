import React from 'react'

const ShopContent = (props) => {
    const title = props.title;
    const description = props.description;
    const image = props.image;
    return (
        <>
            {/* Eductaional Resource Sec Start Here */}
            <section className="educational_sec">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-7">
                            <div className="content-wrapper wow animate__animated animate__fadeInLeft">
                                <h2 className="title">{title}</h2>
                                <p>
                                    {description}
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-5">
                            <div className="img-box wow animate__animated animate__fadeInRight animate__delay-1s">
                                <figure><img src={image} alt={image} className="img-fluid" /></figure>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* Eductaional Resource Sec End Here */}
        </>
    )
}

export default ShopContent
