import React from "react";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import InnerBanner from "../../components/InnerBanner/InnerBanner";
import {
  BannerImage3,
  EducationalResourceImg,
  Product8,
  Product9,
  HandHygieneImg,
} from "../../constant/Index";
import "../../assets/css/shop.css";
import { Link } from "react-router-dom";
import Product from "../../components/Product/Product";
import ShopContent from "../../components/ShopContent/ShopContent";

const HandHygiene = () => {
  const ProductDatas = [
    {
      id: 1,
      image: Product8,
      name: "AMS Compendium Bundle",
      price: "2079",
    },
    {
      id: 2,
      image: Product9,
      name: "AMS Compendium Bundle",
      price: "2079",
    },
  ];
  return (
    <>
      {/* Header Start Here */}
      <Header />
      {/* Header End Here */}

      {/* InnerBanner Sec Start Here */}
      <InnerBanner PageName="" BannerImage={BannerImage3} />
      {/* InnerBanner Sec End Here */}

      {/* Eductaional Resource Sec Start Here */}
      <ShopContent
        title="Hand Hygiene Kits"
        description="Bug Control’s Hand Hygiene Training Kits are ideal for teaching your staff proper handwashing techniques. They contain a special cream that fluoresces under UV light (provided by the included torch) , showing staff where their current handwashing techniques are missing. They can also show how these germs are spread across surfaces and between people and the environment. They are excellent, easy-to-understand tools for demonstrating the importance of proper hand hygiene in stopping the spread of infections in aged care."
        image={HandHygieneImg}
      />
      {/* Eductaional Resource Sec End Here */}

      {/* Products Start Here */}
      <section className="product_sec">
        <div className="container">
          <div className="row">
            <Product ProductData={ProductDatas} />
          </div>
          {/* <ul className="pagination">
                        <li><Link to="#" className='active'>1</Link></li>
                        <li><Link to="#">2</Link></li>
                        <li><Link to="#"><i className="fa fa-long-arrow-right" aria-hidden="true"></i></Link></li>
                    </ul> */}
        </div>
      </section>
      {/* Products End Here */}

      {/* Footer Start Here */}
      <Footer />
      {/* Footer End Here */}
    </>
  );
};

export default HandHygiene;
