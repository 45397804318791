import React from "react";
import { Link, useLocation } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";

import { Pagination } from "swiper";
import config from "../../config";

const Blog = (props) => {
  const location = useLocation();
  const currentLocation = location.pathname;

  const blogData = props.blogData;
  return (
    <>
      {/* Blog Start Here */}
      {currentLocation == "/" ? (
        <Swiper
          modules={[Pagination]}
          navigation={{
            prevEl: "prev",
            nextEl: "next",
          }}
          breakpoints={{
            // when window width is >= 320px
            320: {
              slidesPerView: 1,
              spaceBetween: 20,
            },
            // when window width is >= 640px
            640: {
              slidesPerView: 2,
              spaceBetween: 40,
            },
            992: {
              slidesPerView: 3,
              spaceBetween: 40,
            },
          }}
          spaceBetween={50}
          slidesPerView={3}
          pagination={{ clickable: true }}
        >
          {blogData?.map((data, index) => (
            <SwiperSlide key={`blog_${index}`}>
              <Link to={`/news-detail/${data.id}`}>
                <div className="blog-box wow animate__animated animate__flipInX">
                  <div className="blog-image">
                    <figure>
                      <img
                        src={config.returnImage(data.cover)}
                        alt="blog-image"
                        className="img-fluid"
                      />
                    </figure>
                  </div>
                  <div className="blog-content">
                    <h4 className="title">{data?.title}</h4>
                    <p>{data?.content}</p>
                    <div className="button-group">
                      <Link to={`/news-detail/${data.id}`}>Read more</Link>
                    </div>
                  </div>
                </div>
              </Link>
            </SwiperSlide>
          ))}
        </Swiper>
      ) : (
        <>
          {blogData?.map((data, index) => (
            <div className="col-lg-4 col-md-6" key={`blog_${index}`}>
              <Link to={`/news-detail/${data.id}`}>
                <div className="blog-box wow animate__animated animate__flipInX">
                  <div className="blog-image">
                    <figure>
                      <img
                        src={config.returnImage(data.cover)}
                        alt="blog-image"
                        className="img-fluid"
                      />
                    </figure>
                  </div>
                  <div className="blog-content">
                    <h4 className="title">{data?.title}</h4>
                    <p>{data?.content}</p>
                    <div className="button-group">
                      <Link to={`/news-detail/${data.id}`}>Read more</Link>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          ))}
        </>
      )}
      {/* Blog End Here */}
    </>
  );
};

export default Blog;
