import React from "react";
import { Link } from "react-router-dom";
import { kwdiImg, Logo } from "../../constant/Index";

const Footer = () => {
  return (
    <>
      {/* Footer Sec Start Here */}
      <footer>
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="footer-content">
                <div className="logo-box">
                  <Link to="#">
                    <figure>
                      <img src={Logo} alt="footer-logo" className="img-fluid" />
                    </figure>
                  </Link>
                </div>
                <ul className="info">
                  <li>
                    <a href="mailto:info@infectionprevention.co.nz">
                      info@infectionprevention.co.nz
                    </a>
                  </li>
                  <li>
                    <a href="tel:0800849211">0800 849 211</a>
                  </li>
                  <li>
                    <a href="#">
                      BCP Trust: PO Box 42024, Tower Junction, Christchurch, New
                      Zealand 8149
                    </a>
                  </li>
                  <li className="social-icons">
                    <a
                      href="https://www.facebook.com/BugControlAustraliaAndNewZealand/"
                      target={"_blank"}
                    >
                      <i
                        className="fa fa-facebook-official"
                        aria-hidden="true"
                      ></i>
                    </a>
                    <span className="seprator"></span>
                    <a
                      href="https://www.linkedin.com/company/bug-control-australia-&-new-zealand-infection-control-consultants/"
                      target="_blank"
                    >
                      <i
                        className="fa fa-linkedin-square"
                        aria-hidden="true"
                      ></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row">
                <div className="col-md-6">
                  <div className="footer-content">
                    <ul className="menu-links">
                      <li>
                        <Link
                          target="_blank"
                          to="https://identity.document360.io/Account/Login?ReturnUrl=%2Fconnect%2Fauthorize%2Fcallback%3Fclient_id%3Ddocument360MVC%26redirect_uri%3Dhttps%253A%252F%252Fbug-control.document360.io%252Fsignin-oidc%26response_type%3Dcode%26scope%3Dopenid%2520email%2520profile%26code_challenge%3DHXRK5HPW_pXmhxEzcB63eeM-btcUJ-qNtV_w2ViH2ew%26code_challenge_method%3DS256%26nonce%3D638120148667997455."
                        >
                          Knowledge Base Login
                        </Link>
                      </li>
                      <li>
                        <Link to="/packages">Subscription</Link>
                      </li>
                      <li>
                        <Link to="/shop">Shop</Link>
                      </li>
                      <li>
                        <Link to="/bc-systems">BC Systems</Link>
                      </li>
                      <li>
                        <Link to="/contact-us">Contact</Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="footer-content">
                    <ul className="menu-links">
                      <li>
                        <Link
                          target="_blank"
                          to="https://infectioncontrol.talentlms.com/index"
                        >
                          LMS Login
                        </Link>
                      </li>
                      <li>
                        <Link to="/lead-course">Education</Link>
                      </li>
                      <li>
                        <Link to="/about-us">About</Link>
                      </li>
                      <li>
                        <Link to="/news">News</Link>
                      </li>
                      <li>
                        <Link to="/terms-and-condition">Legal</Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="note">
                <p>
                  Bug Control recognises the unique role of Māori as Tangata
                  Whenua and embraces Te Tiriti o Waitangi recognising Māori as
                  tino rangitiratanga of Aotearoa/New Zealand while embracing
                  the three guiding principles of the Treaty – Partnership
                  Participation and Protection. We will endeavour to implement
                  bicultural policies and practices that incorporate and value
                  Māori cultural concepts, values and practices.
                </p>
              </div>
            </div>
          </div>
          <div className="copyright">
            <p className="rights">© Copyright Bug Control, 2023</p>
            <div className="img-box">
              <a href="#">
                <figure>
                  <img src={kwdiImg} alt="kwdi" className="img-fluid" />
                </figure>
              </a>
            </div>
          </div>
        </div>
      </footer>
      {/* Footer Sec End Here */}
    </>
  );
};

export default Footer;
