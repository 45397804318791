export const LOGIN = "LOGIN";
export const GET_PAYMENT_METHODS = "GET_PAYMENT_METHODS";
export const ADD_PAYMENT_METHOD = "ADD_PAYMENT_METHOD";
export const GET_CATEGORIES = "GET_CATEGORIES";
export const GET_ADDRESSES = "GET_ADDRESSES";
export const ADD_ADDRESS = "ADD_ADDRESS";
export const UPDATE_PROFILE = "UPDATE_PROFILE";
export const GET_ORDERS = "GET_ORDERS";
export const CHECKOUT = "CHECKOUT";

export const CLEAR_PAYMENT_METHODS = "CLEAR_PAYMENT_METHODS";
export const CLEAR_ADDRESSES = "CLEAR_ADDRESSES";
export const CLEAR_ORDERS = "CLEAR_ORDERS";

export const ADD_TO_CART = "ADD_TO_CART";
export const UPDATE_CART = "UPDATE_CART";
export const REMOVE_FROM_CART = "REMOVE_FROM_CART";
export const CLEAR_CART = "CLEAR_CART";

export const GET_PACKAGES = "GET_PACKAGES";

export const LOGOUT = "LOGOUT";
