import React from "react";
import config from "../config";

const Package = ({
  id,
  name,
  price,
  inclusions = [],
  onBuy = null,
  animationDelay = 0,
  currentSubscription = false,
}) => {
  return (
    <div
      className={
        `package-box wow animate__animated animate__bounceIn ` +
        (animationDelay > 0 ? `animate__delay-${animationDelay}s` : "")
      }
    >
      <div className="title">
        <h3>{name}</h3>
      </div>
      <div className="price">
        <h3>{price == 0 ? "FREE" : "$" + config.numberFormat(price, 0)}</h3>
      </div>
      <ul className="list">
        {inclusions.map((singleInclusion) => (
          <li key={`package_${id}_inclusion_${singleInclusion.id}`}>
            {singleInclusion.includes}
          </li>
        ))}
      </ul>
      <div className="button-group">
        <button
          type="button"
          className="primary-btn"
          onClick={onBuy}
          disabled={currentSubscription}
        >
          {currentSubscription ? "Currently Active" : "Get it"}
        </button>
      </div>
    </div>
  );
};

export default Package;
