import React, { useEffect } from "react";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import { review_img } from "../../constant/Index";
import Zoom from "react-medium-image-zoom";
import { useState } from "react";
import "../../assets/css/product-detail.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import Product from "../../components/Product/Product";
import config from "../../config";
import { PRODUCTS_ENDPOINT } from "../../endpoints";
import MySpinner from "../../components/MySpinner";
import { connect } from "react-redux";
import { ADD_TO_CART } from "../../redux/actions/types";
import Swal from "sweetalert2";

const ProductDetail = ({ addToCart }) => {
  const { id } = useParams();

  const [product, setProduct] = useState({});
  const [relatedProducts, setRelatedProducts] = useState([]);

  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  const navigateTo404 = () => {
    navigate("/404");
  };

  const getProduct = async () => {
    const res = await config.getApi(
      PRODUCTS_ENDPOINT + "/" + id,
      config.defaultHeaders,
      navigateTo404
    );
    setProduct(res.product);
    setRelatedProducts(res.related_products);
    setLoading(false);
  };

  useEffect(() => {
    getProduct().then();
  }, []);

  const [quantity, setQuantity] = useState(0);
  if (quantity < 1) {
    setQuantity(1);
  }

  const onAddToCart = (showAlert = true) => {
    addToCart({ product_id: id, product: product, quantity });
    if (showAlert) {
      Swal.fire({
        title: "Done",
        icon: "success",
        text: "Product Added to Cart!",
      });
    }
  };

  const onBuyNow = () => {
    onAddToCart(false);
    navigate("/product-cart");
  };

  if (loading) {
    return (
      <>
        <Header />
        <div className="d-flex justify-content-center align-items-center">
          <MySpinner />
        </div>
        <Footer />
      </>
    );
  }

  return (
    <>
      {/* Header Start Here */}
      <Header />
      {/* Header End Here */}

      {/* Product Detail Sec Start Here */}
      <section className="product-detail">
        <div className="container">
          <div className="row">
            <div className="col-lg-5">
              <div className="product-slider-wrapper">
                <div className="main-slider-wrapper">
                  <img
                    src={config.returnImage(product.cover)}
                    className="img-fluid"
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-7">
              <div className="product-detail-content-wrapper">
                <div className="product-breadcrumbs-wrapper">
                  <ul>
                    <li>
                      <Link to="/">Home</Link>
                    </li>
                    <li>
                      <Link to="/shop">Shop</Link>
                    </li>
                    <li>
                      <Link className="active" to="#">
                        {product.name}
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="detail-wrapper">
                  <h3>{product.name}</h3>
                  {/* <h4>AMS Compendium Bundle</h4> */}
                  <div className="reviews-wrapper">
                    <ul>
                      <li>
                        <i className="fa fa-star"></i>
                      </li>
                      <li>
                        <i className="fa fa-star"></i>
                      </li>
                      <li>
                        <i className="fa fa-star"></i>
                      </li>
                      <li>
                        <i className="fa fa-star"></i>
                      </li>
                      <li>
                        <i className="fa fa-star"></i>
                      </li>
                      <li>
                        <span className="total-reviews">5 Reviews </span>
                      </li>
                    </ul>
                  </div>
                  <div className="price-wrapper">
                    <h4>Price:</h4>
                    <h3>${product.price?.toFixed(2)}</h3>
                  </div>
                  <div className="description">
                    <p>{product.description}</p>
                  </div>
                  <div className="quantity-card-wrapper">
                    <div className="quantity-wrapper">
                      <h4>Quantity:</h4>
                      <div className="quantity-input-wrapper">
                        <button
                          onClick={() => setQuantity(quantity - 1)}
                          className="back"
                        >
                          <i className="fa fa-angle-left"></i>
                        </button>
                        <input
                          type="number"
                          value={quantity}
                          onChange={(e) => setQuantity(e.target.value)}
                        />
                        <button
                          onClick={() => setQuantity(quantity + 1)}
                          className="back"
                        >
                          <i className="fa fa-angle-right"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="btn-wrapper">
                    <button className="btn" type="button" onClick={onAddToCart}>
                      Add To Cart
                    </button>
                    <button className="btn" type="button" onClick={onBuyNow}>
                      Buy Now
                    </button>
                  </div>
                  <div className="other-deatils-wrapper">
                    <ul>
                      <li>
                        <span className="property">SKU:</span>
                        <span className="value">{product.sku}</span>
                      </li>
                      <li>
                        <span className="property">Category:</span>
                        <span className="value">{product.category?.name}</span>
                      </li>
                      <li>
                        <span className="property">Tags:</span>
                        <span className="value">{product.tags.join(", ")}</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Product Detail Sec End Here */}

      {/* Product Description Sec Start Here */}
      <section className="products-tab-wrapper">
        <div className="container">
          <div className="row">
            <div className="col-lg-3">
              <div className="details-tab-wrapper">
                <ul className="nav nav-tabs" id="myTab" role="tablist">
                  <li className="nav-item">
                    <a
                      className="nav-link active"
                      id="Description-tab"
                      href="#Description"
                      data-toggle="tab"
                      role="tab"
                      aria-controls="Description"
                      aria-selected="true"
                    >
                      Description
                    </a>
                  </li>
                  <li className="nav-item">
                    <button
                      className="nav-link"
                      id="Reviews-tab"
                      data-toggle="tab"
                      data-target="#Reviews"
                      role="tab"
                      aria-controls="Reviews"
                      aria-selected="false"
                    >
                      Reviews <span>(5)</span>
                    </button>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-9">
              <div className="tab-content" id="myTabContent">
                <div
                  className="tab-pane fade show active"
                  id="Description"
                  role="tabpanel"
                  aria-labelledby="Description-tab"
                >
                  <div className="description-wrapper">
                    <div className="heading-warpper">
                      <h2>Description</h2>
                    </div>
                    <p>{product.description}</p>
                  </div>
                  <div className="contains_box">
                    <div className="heading-wrapper">
                      <h2>Contains</h2>
                    </div>
                    <p dangerouslySetInnerHTML={{ __html: product.contains }}>
                      {/* Increased antibiotic use in the aged care setting has
                      become a significant threat to our residents with the
                      increasing use of antibiotics in facilities and the
                      community. */}
                    </p>
                    {/* <ul className="points">
                      <li>Includes 10 videos</li>
                      <li>
                        Tools, Guides, Flowcharts, Posters and so much more
                      </li>
                      <li>Contains 383 Pieces</li>
                    </ul> */}
                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="Reviews"
                  role="tabpanel"
                  aria-labelledby="Reviews-tab"
                >
                  <div className="reviews-wrapper">
                    <div className="heading-wrapper">
                      <h5>Our Clients Reviews</h5>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et
                        dolore.Lorem ipsum dolor sit amet, consectetur
                        adipiscing elit, sed do eiusmod tempor incididunt ut
                        labore et dolore.Lorem ipsum dolor sit amet,Lorem ipsum
                        dolor sit amet
                      </p>
                    </div>
                    <div className="single-review-wrapper">
                      <div className="single-img-wrapper">
                        <figure>
                          <img src={review_img} className="img-fluid" alt="" />
                        </figure>
                      </div>
                      <div className="single-reviews-content-wrapper">
                        <h5>John Doe</h5>
                        <h6>08 Nov 2022</h6>
                        <p>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit, sed do eiusmod tempor incididunt ut labore et
                          dolore magna aliqua. Ut enim ad minim veniam, quis
                          nostrud exercitation ullamco laboris nisLorem ipsum
                          dolor sit amet, consectetur adipiscing elit, sed do
                          eiusmod tempor incididunt ut labore et
                        </p>
                      </div>
                    </div>
                    <div className="single-review-wrapper">
                      <div className="single-img-wrapper">
                        <figure>
                          <img src={review_img} className="img-fluid" alt="" />
                        </figure>
                      </div>
                      <div className="single-reviews-content-wrapper">
                        <h5>John Doe</h5>
                        <h6>08 Nov 2022</h6>
                        <p>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit, sed do eiusmod tempor incididunt ut labore et
                          dolore magna aliqua. Ut enim ad minim veniam, quis
                          nostrud exercitation ullamco laboris nisLorem ipsum
                          dolor sit amet, consectetur adipiscing elit, sed do
                          eiusmod tempor incididunt ut labore et
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="add-review-wrapper">
                    <div className="heading-wrapper">
                      <h3>Add Review</h3>
                    </div>
                    <div className="review-form-wrapper">
                      <form action="">
                        <div className="form-group row">
                          <div className="col-lg-4">
                            <input
                              className="form-control"
                              placeholder="Name"
                              type="text"
                            />
                          </div>

                          <div className="col-lg-4">
                            <input
                              className="form-control"
                              placeholder="Email"
                              type="text"
                            />
                          </div>
                          <div className="col-lg-4">
                            <div className="rate-wrapper">
                              <label htmlFor="">Your Rating</label>
                              <div id="full-stars-example-two">
                                <div className="rating-group">
                                  <input
                                    disabled
                                    checked
                                    className="rating__input rating__input--none"
                                    name="rating3"
                                    id="rating3-none"
                                    value="0"
                                    type="radio"
                                  />
                                  <label
                                    aria-label="1 star"
                                    className="rating__label"
                                    htmlFor="rating3-1"
                                  >
                                    <i className="rating__icon rating__icon--star fa fa-star"></i>
                                  </label>
                                  <input
                                    className="rating__input"
                                    name="rating3"
                                    id="rating3-1"
                                    value="1"
                                    type="radio"
                                  />
                                  <label
                                    aria-label="2 stars"
                                    className="rating__label"
                                    htmlFor="rating3-2"
                                  >
                                    <i className="rating__icon rating__icon--star fa fa-star"></i>
                                  </label>
                                  <input
                                    className="rating__input"
                                    name="rating3"
                                    id="rating3-2"
                                    value="2"
                                    type="radio"
                                  />
                                  <label
                                    aria-label="3 stars"
                                    className="rating__label"
                                    htmlFor="rating3-3"
                                  >
                                    <i className="rating__icon rating__icon--star fa fa-star"></i>
                                  </label>
                                  <input
                                    className="rating__input"
                                    name="rating3"
                                    id="rating3-3"
                                    value="3"
                                    type="radio"
                                  />
                                  <label
                                    aria-label="4 stars"
                                    className="rating__label"
                                    htmlFor="rating3-4"
                                  >
                                    <i className="rating__icon rating__icon--star fa fa-star"></i>
                                  </label>
                                  <input
                                    className="rating__input"
                                    name="rating3"
                                    id="rating3-4"
                                    value="4"
                                    type="radio"
                                  />
                                  <label
                                    aria-label="5 stars"
                                    className="rating__label"
                                    htmlFor="rating3-5"
                                  >
                                    <i className="rating__icon rating__icon--star fa fa-star"></i>
                                  </label>
                                  <input
                                    className="rating__input"
                                    name="rating3"
                                    id="rating3-5"
                                    value="5"
                                    type="radio"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="form-group">
                          <textarea
                            name=""
                            placeholder="Your Review"
                            className="form-control"
                            id=""
                            cols="30"
                            rows="5"
                          ></textarea>
                        </div>
                        <div className="form-group row mt-4">
                          <div className="col-lg-10">
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                value=""
                                id="flexCheckDefault"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="flexCheckDefault"
                              >
                                By using this form you agree with the storage
                                and handling of your data by this website.
                              </label>
                            </div>
                          </div>
                          <div className="col-lg-2">
                            <button type="submit" className="primary-btn">
                              Submit
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Product Description Sec End Here */}

      {/* Related Product Sec Start Here */}
      <section className="related_sec">
        <div className="container">
          <div className="heading-wrapper pb-4">
            <h2>Related Products</h2>
          </div>
          <div className="row">
            <Product ProductData={relatedProducts} />
          </div>
        </div>
      </section>
      {/* Related Product Sec End Here */}

      {/* Footer Start Here */}
      <Footer />
      {/* Footer End Here */}
    </>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  addToCart: (cartItem) => dispatch({ type: ADD_TO_CART, payload: cartItem }),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductDetail);
