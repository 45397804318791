import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Footer from "../components/Footer/Footer";
import Header from "../components/Header/Header";
import InnerBanner from "../components/InnerBanner/InnerBanner";
import MySpinner from "../components/MySpinner";
import Product from "../components/Product/Product";
import ShopContent from "../components/ShopContent/ShopContent";
import config from "../config";
import { BannerImage3 } from "../constant/Index";
import { CATEGORIES_ENDPOINT } from "../endpoints";

const Category = () => {
  const [category, setCategory] = useState({});
  const [loading, setLoading] = useState(true);

  const { slug } = useParams();

  useEffect(() => {
    config
      .getApi(CATEGORIES_ENDPOINT + "/" + slug, config.defaultHeaders)
      .then((res) => {
        setCategory(res);
        setLoading(false);
      });
  }, [slug]);

  return (
    <>
      {/* Header Start Here */}
      <Header />
      {/* Header End Here */}

      {/* InnerBanner Start Here */}
      <InnerBanner BannerImage={BannerImage3} />
      {/* InnerBanner End Here */}

      {!loading ? (
        <>
          {/* Content Sec Start Here */}
          <ShopContent
            title={category.name}
            description={category.description}
            image={config.returnImage(category.cover)}
          />
          {/* Content Sec End Here */}

          {/* Products Start Here */}
          <section className="product_sec">
            <div className="container">
              <div className="row">
                <Product ProductData={category?.products} />
              </div>
              {/* <ul className="pagination">
                            <li><Link to="#" className='active'>1</Link></li>
                            <li><Link to="#">2</Link></li>
                            <li><Link to="#"><i className="fa fa-long-arrow-right" aria-hidden="true"></i></Link></li>
                        </ul> */}
            </div>
          </section>
          {/* Products End Here */}
        </>
      ) : (
        <div className="d-flex justify-content-center align-items-center p-5">
          <MySpinner color={config.primaryColor} />
        </div>
      )}

      {/* Footer Start Here */}
      <Footer />
      {/* Footer End Here */}
    </>
  );
};

export default Category;
