import { GET_ADDRESSES, ADD_ADDRESS, CLEAR_ADDRESSES } from "../actions/types";

const addresses = (state = [], action) => {
  switch (action.type) {
    case GET_ADDRESSES:
      return action.payload;
    case ADD_ADDRESS:
      return [...state, action.payload];
    case CLEAR_ADDRESSES:
      return [];
    default:
      return state;
  }
};

export default addresses;
