import React from "react";
import config from "../config";

const PaymentMethodSelector = ({
  paymentMethod,
  selected = false,
  onChange,
}) => {
  return (
    <label
      className={`container ${!paymentMethod.is_default ? "second-card" : ""}`}
    >
      <input
        type="radio"
        checked={selected}
        name="payment-radio"
        onChange={(e) => {
          if (e.target.checked) {
            onChange(paymentMethod.id);
          }
        }}
      />
      <span className="checkmark">
        <div className="card-details">
          <div className="radio-btn">
            <span></span>
          </div>
          <div className="visa-card">
            <div className="img-box">
              <figure>
                <img
                  style={{ height: 48, width: 78 }}
                  src={config.returnCardImage(paymentMethod.card_brand)}
                  alt={paymentMethod.card_brand}
                />
              </figure>
            </div>
            <div className="input-box">
              <label htmlFor="card-number" className="d-block">
                {paymentMethod.card_brand}
              </label>
              <input
                id="card-number"
                value={"**********" + paymentMethod.last_4}
                type="text"
                readOnly
              />
            </div>
          </div>
          <div className="extra-btn">
            <button className="btn default disabled">Default</button>
            <div className="selection">
              <i className="fa fa-check" aria-hidden="true"></i>
            </div>
          </div>
        </div>
      </span>
    </label>
  );
};

export default PaymentMethodSelector;
