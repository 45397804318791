import config from "../../config";
import { ORDERS_ENDPOINT } from "../../endpoints";
import { GET_ORDERS, CHECKOUT, CLEAR_CART } from "./types";

export const GetOrdersAction = () => async (dispatch, getState) => {
  const res = await config.getApi(
    ORDERS_ENDPOINT,
    config.bearerHeaders(getState().auth.token)
  );
  if (res) {
    dispatch({ type: GET_ORDERS, payload: res });
  }
};

export const CheckoutAction =
  ({ payment_method_id, address_id, items, total, subtotal }) =>
  async (dispatch, getState) => {
    const headers = config.bearerHeaders(getState().auth.token);
    const res = await config.postApi(
      ORDERS_ENDPOINT,
      headers,
      JSON.stringify({ payment_method_id, address_id, total, subtotal, items })
    );
    if (res) {
      dispatch({ type: CHECKOUT, payload: res });
      dispatch({ type: CLEAR_CART });
      return res.id;
    }
  };
