import { GET_PACKAGES } from "../actions/types";

const packages = (state = [], action) => {
  switch (action.type) {
    case GET_PACKAGES:
      return action.payload;
    default:
      return state;
  }
};

export default packages;
